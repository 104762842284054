import React, { Component } from "react";
import Header from "../common/layout/header";
import Footer from "../common/layout/footer";
import { http } from "../helper";
import DataTable from "react-data-table-component";
import { read, utils } from "xlsx";
import { BaseUrl } from "../common/config";
import Sidebar from "../common/sidebar";
import CommonAlert from "../common/Commonalert";
import Popup from "../common/popup";
import ChangePassword from "./changepassword";
import EditvendorDetails from "./editvendorDetails";
import Importpopup from "../common/importpopup";
import { dateformatwithuktime } from "../utilityfunctions";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

class Vendors extends Component {
  
  constructor(props) {
    super(props);
    this.showConfirmationDialog = this.showConfirmationDialog.bind(this);
    this.showReasonDialog = this.showReasonDialog.bind(this);
    this.state = {
      searchTextVal: "",
      accounts: [],
      tempaccounts: [],
      loading: true,
      popupView: "",
      showPopup: false,
      //showLoader: false,
      Vendoremailadd: "",
      alertMessage: "",
      alertPopup: false,
      visibleAlert: false,
      customeremail: "",
      customeraccount: "",
      showDialog: false,
      editvendor: false,
      isActive: [],
      reason: "",
      showReasonPopup: false,
      reasonError: "",
      popuplogView: "",
      popupPricebandView: "",
      selectedFile: null,
      isUploading: false,
      uploadMessage: "",
      showcustomPopup: false,
      popupImportView: false,
      importValue: "",
      searchResults: [],
      statusFilterVal: "",
      visibledeleteAlert: false,
      sortBy: 'all',
      range: '',
      showDatePicker: false,
      fromDate: null,
      toDate: null,
      selectedCategories: ["pizza", "pasta", "burrito"],
    };
    this.fileInputRef = React.createRef();

    if (!sessionStorage.getItem("isLoggedIn")) window.location.href = "/";

    if (
      JSON.parse(sessionStorage.getItem("authData")) != "" &&
      !JSON.parse(
        sessionStorage.getItem("authData")
      ).adminRole.permissions.includes("manage_customers")
    ) {
      window.location.href = "/unauthorized";
    }

    this.handleImport = this.handleImport.bind(this);
  }

  priceFormat = (price) => {
   
    return price.toLocaleString("en-US", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
    });
};

  
  handleImport($event) {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        var loginDetails = {};
        if (window.sessionStorage.getItem("authData")) {
          loginDetails = JSON.parse(window.sessionStorage.getItem("authData"));
        }

        if (sheets.length) {
          let rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          let URL = BaseUrl + "admin/account/import";

          const payload = {
            data: rows,
          };

          http
            .post(URL, payload)
            .then((response) => {
              this.getAccounts();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  

  getAccounts() {
    this.setState({ loading: true });
    var loginDetails = {};
    if (window.sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(window.sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/account";
    http
      .get(URL, {}, { timeout: 180000 })
      .then((response) => {
        this.setState({
          accounts: response.data.data,
          loading: false,
          tempaccounts: response.data.data,
          alertPopup: false,
          alertMessage: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertPopup: true,
          alertMessage: `The Server is busy, please try after sometime`,
        });
      });
  }
  editVendorDetails(vendor) {
    this.setState({
      editvendor: true,
      VendorDetails: vendor,
      alertMessage: "",
    });
  }
  ediVendorsdetails = (formData) => {
    let URL = BaseUrl + "admin/editaccount/" + formData.cid;

    this.setState({ loading: true });

    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    http
      .put(URL, formData)
      .then((response) => {
        let responseData = response.data.success;
        if (responseData) {
          this.setState({
            alertPopup: true,
            alertMessage: "Account Details Updated Successfully",
          });
        }
        this.setState({ editvendor: false, loading: false });
        //setTimeout(this.getAccounts(),1000);
        setTimeout(() => {
          window.location.reload();
        }, 2000); // 2000 milliseconds (2 seconds)
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };
  // Define the sanitizeString function
  sanitizeString = (input) => {
    // Remove spaces or commas at the beginning and end of the string
    let sanitized = input.trim().replace(/^,|,$/g, "");

    // // Remove spaces around commas
    sanitized = sanitized.trim();

    return sanitized;
  };

  viewVendorDetails(vendor) {
    this.setState({ alertPopup: false, alertMessage: "" });
    let addressFields = [
      this.sanitizeString(vendor.accountAddress1 || ""),
      this.sanitizeString(vendor.accountAddress2 || ""),
      this.sanitizeString(vendor.accountAddress3 || ""),
      this.sanitizeString(vendor.accountAddress4 || ""),
      this.sanitizeString(vendor.accountAddress5 || ""),
    ];
    var accountaddress = addressFields
      .filter((field) => field !== undefined && field != "")
      .join(", ");
    let popupResult = (
      <Popup
        extClass="order-pop info-poup"
        headMoreTxt={
          <>
            <div className="order-top">
              <div className="head">
                <h4>Customer Details</h4>
              </div>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="order-top">
              <div className="bottom vendor-details vendor-table-view">
                <table className="vendor-table">
                  <tr>
                    <td>Name</td>
                    <td>{vendor.accountName}</td>
                  </tr>
                  <tr>
                    <td>Account Code</td>
                    <td>{vendor.accountCode}</td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td>{vendor.accountEmail}</td>
                  </tr>
                  {vendor.accountTelephone !== "0" && (
                    <tr>
                      <td>Telephone Number</td>
                      <td>{vendor.accountTelephone}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Address</td>
                    <td>{accountaddress}</td>
                  </tr>
                  <tr>
                    <td>Accept CWO Only</td>
                    <td>{vendor.accountPaymentMethod}</td>
                  </tr>
                  <tr>
                    <td>Route Days</td>
                    <td>{vendor.accountDeliverableDays}</td>
                  </tr>
                  <tr>
                    <td>Price Band</td>
                    <td>{vendor.accountPriceListId}</td>
                  </tr>
                  <tr>
                    <td>Credit Limit</td>
                    <td>{vendor.CreditLimit}</td>
                  </tr>
                  <tr>
                    <td>Account Objective1</td>
                    <td>{vendor.AccountObjective1}</td>
                  </tr>
                  <tr>
                    <td>Account Objective2</td>
                    <td>{vendor.AccountObjective2}</td>
                  </tr>
                  <tr>
                    <td>Website Access</td>
                    <td>{vendor.accountWebSiteAccess}</td>
                  </tr>
                  {vendor.Sectors && (
                    <tr>
                      <td>Sector</td>
                      <td>{vendor.Sectors}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Created Date</td>
                    <td>{dateformatwithuktime(vendor.createdAt)}</td>
                  </tr>
                  <tr>
                    <td>Updated Date</td>
                    <td>{dateformatwithuktime(vendor.updatedAt)}</td>
                  </tr>
                  {vendor.lastLogin && (<tr>
                    <td>Last Login</td>
                    <td>{dateformatwithuktime(vendor.lastLogin)}</td>
                  </tr>)}
                </table>
              </div>
            </div>
          </>
        }
        closebtn={
          <>
            <span
              onClick={() => this.setState({ popupView: "" })}
              className="close"
            ></span>
          </>
        }
      />
    );

    this.setState({ popupView: popupResult });
  }

  showConfirmationDialog = () => {
    if (this.state.reason !== "") {
      this.setState({
        alertPopup: false,
        showReasonPopup: false,
        showDialog: true,
      });
    } else {
      this.setState({ reasonError: true });
    }
  };

  showReasonDialog = (accountcode, action) => {
    this.setState(
      {
        showReasonPopup: true,
        reasonError: false,
        confirmationAction: { accountcode, action },
      },
      () => { }
    );
  };

  hideConfirmationDialog = () => {
    this.setState({
      alertPopup: false,
      showDialog: false,
      showReasonPopup: false,
      reason: "",
      reasonError: false,
    });
  };

  confirmActionHandler = () => {
    const { accountcode, action } = this.state.confirmationAction;
    this.confirmAction(accountcode, action);
    this.hideConfirmationDialog();
  };

  toggleCustomerStatus = (accountcode) => {
    const { accounts } = this.state;
    const account = accounts.find((p) => p.accountCode === accountcode);
    const isCurrentlyActive = account.accountStatus;

    const action = isCurrentlyActive === "active" ? "deactivate" : "activate";

    this.showReasonDialog(accountcode, action);
  };
  confirmAction = (accountcode, action) => {
    const { confirmationAction } = this.state;
    const { accounts, isActive, reason } = this.state;
    let URL = BaseUrl + "admin/accountstatus";
    const payload = {
      accountCode: accountcode,
      accountStatus: action === "activate" ? "active" : "deactive",
    };
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    http
      .put(URL, payload)
      .then(async (response) => {
        if (response.status === 200) {
          //changed by k2b Sangeetha for activate/Deactivate Customer
          this.setState((prevState) => {
            const updatedAccounts = prevState.accounts.map((account) =>
              account.accountCode === accountcode
                ? { ...account, accountStatus: payload.accountStatus }
                : account
            );
            const updatedTempAccounts = prevState.tempaccounts.map((account) =>
              account.accountCode === accountcode
                ? { ...account, accountStatus: payload.accountStatus }
                : account
            );
            return {
              accounts: updatedAccounts,
              tempaccounts: updatedTempAccounts,
            };
          });
          //changed by k2b Sangeetha for activate/Deactivate Customer
          let noteURL = BaseUrl + "admin/accountlog";
          const notepayload = {
            accountCode: accountcode,
            noteText: action + " - " + reason,
            createdBy: loginDetails.adminName,
          };
          http.post(noteURL, notepayload).then(async (response) => {
            const actionMsg =
              action.trim() === "activate" ? "activated" : "deactivated";
            this.setState({
              showcustomPopup: true,
              uploadMessage: `Customer has been ${actionMsg} successfully!`,
            });
          });
          this.hideConfirmationDialog();
        }
      })
      .catch((error) => {
        console.error("Error updating customer status:", error);
        alert("Failed to update customer status.");
      });
  };
  /* added by k2b vivek customer active and deactive status */
  changePassword(customer) {
    this.setState({ showPopup: true });
    this.setState({
      Vendoremailadd: customer.accountEmail,
      Vendoraccountcode: customer.accountCode,
    });
  }

  triggerEmail = (customer) => {
    this.setState({ showPopup: false, alertMessage: '', visibleAlert: true, customeremail: customer.accountEmail, customeraccount: customer.accountCode });

    //this.setState({alertPopup: true, alertMessage: 'For demo purpose we had disabled the email functionality!!!',visibleAlert:false,customeremail:'', showPopup:false});
  };
  CustomerStatuslog = (accountCode) => {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/accountlog/" + accountCode;

    http
      .get(URL)
      .then(async (response) => {
        let responseData = [];
        responseData = response.data.data;
        this.viewlogDetails(responseData, accountCode);
      })
      .catch((error) => {
        console.error("Error updating customer status:", error);
      });
  };
  formatDate(dateString) {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  }

  viewlogDetails(acc_det, accountCode) {
    let popupResult = (
      <Popup
        extClass="info-poup show"
        closebtn={
          <>
            <span
              onClick={() => this.setState({ popuplogView: "" })}
              className="close"
            ></span>
          </>
        }
        headMoreTxt={
          <>
            <div className="order-top">
              <h4>
                <span>Customer Status Log Details - {accountCode}</span>
              </h4>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="bottom vendor-details acc-log">
              {acc_det && acc_det.length > 0 ? (
                acc_det.map((acc_item) => {
                  const [accstatus, notes] = acc_item.noteText.split(/-(.+)/);
                  let accactionstatus =
                    accstatus.trim() === "activate"
                      ? "Activated"
                      : "Deactivated";
                  let createdby = acc_item.createdBy ? (
                    <>
                      <span className="log-user">{acc_item.createdBy}</span>
                      <span className="log-line">|</span>
                    </>
                  ) : (
                    ""
                  );
                  return (
                    <div className="info-item">
                      <div className="info-innr">
                        <div className="flex-log-hdr">
                          <p>
                            <span className="labeltxt acn-by">
                              {accactionstatus}
                            </span>
                          </p>
                          <div className="hdrlog-rit">
                            {createdby}
                            <span className="log-date">
                              {dateformatwithuktime(acc_item.createdAt)}
                            </span>
                          </div>
                        </div>
                        <p>
                          <span className="labeltxt log-reason">Reason: </span>
                          {notes ? notes.trim() : ""}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-logs">No logs available</div>
              )}
            </div>
          </>
        }
      />
    );

    this.setState({ popuplogView: popupResult });
  }

  triggerMail = () => {
    let URL = BaseUrl + "forgotpassword";
    let payload = {
      emailaddress: this.state.customeremail,
      accountCode: this.state.customeraccount,
      type: "admin",
    };

    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }
    this.setState({ visibleAlert: false, loading: true });

    http
      .post(URL, payload)
      .then((response) => {
        this.setState({
          alertPopup: true,
          alertMessage:
            "Reset password link has been sent to your Email Address",
          loading: false,
          customeremail: "",
          showPopup: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  alertPopupclose = () => {
    this.setState({
      visibleAlert: false,
      customeremail: "",
      customeraccount: "",
      alertPopup: false,
    });
  };

  DeletePopupclose = () => {
    this.setState({
      visibledeleteAlert: false,
      customeraccount: "",
      alertPopup: false,
    });
  };

  closepopup = (alrtpopup, alrtmsg) => {
    this.setState({
      showPopup: false,
      alertPopup: alrtpopup,
      alertMessage: alrtmsg,
    });
  };

  editclosepopup = (alrtpopup, alrtmsg) => {
    this.setState({
      editvendor: false,
      alertPopup: alrtpopup,
      alertMessage: alrtmsg,
      showPopup: false,
    });
  };

  viewProducts = (vendor)=>{
    this.setState({ alertPopup: false, alertMessage: "" });
    let URL = BaseUrl + `admin/productsbasedonband/${vendor.accountPriceListId}`;
    http
    .get(URL)
    .then((response) => {
      let responseProducts = response.data.data;
      
      this.setState({originalProducts: responseProducts},()=>
      {this.viewProductsPopup(responseProducts,vendor.accountPriceListId)}
      );
      
    })
    .catch((err) => {
      console.log(err);
    });
  }
  
  getFilteredProducts = (responseProducts) => {
    const {selectedCategories } = this.state;
    // Category mapping
     const categoryMap = {
      pizza: ['1'],
      pasta: ['10', '13'],
      burrito: ['11', '12'],
    };
    const selectedCategoryIds = selectedCategories
    .flatMap((category) => categoryMap[category]|| []);

    responseProducts.sort((a, b) => parseFloat(a.PDP_Price1) - parseFloat(b.PDP_Price1));

  return responseProducts.filter((prd) => selectedCategoryIds.includes(prd.categoryId));
  };

 
  viewProductsPopup =(responseProducts,priceband)=>{
      const filteredProducts = this.getFilteredProducts(responseProducts);
   
      let popupResult = (<Popup
        extClass="order-pop info-poup"
        headMoreTxt={
          <>
            <div className="order-top">
              <div className="head">
                <h4>Product Details - {responseProducts[0].PDP_Reference}</h4>
              </div>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="order-top">
              <div className="bottom product-details vendor-table-view">
              <div className="custom_check-box">
                      <div className='edit-free-cat-chks'>
                      <div className="check-box inline">
                          <input
                            type="checkbox"
                            id="cat01"
                            name="pizza"
                            onChange={this.handleCategoryChange}
                            checked={this.state.selectedCategories.includes("pizza")}
                          />
                          <label for="cat01">Pizza <span class="ctgry-icon pizza"></span></label>
                        </div>
                        <div class="check-box inline">
                          <input
                            type="checkbox"
                            id="cat02"
                            name="pasta"
                            onChange={this.handleCategoryChange}
                            checked={this.state.selectedCategories.includes("pasta")}
                          />
                          <label for="cat02">Pasta, Rice & Noodle Sauces <span class="ctgry-icon pasta"></span></label>
                        </div>
                        <div class="check-box inline">
                          <input
                            type="checkbox"
                            id="cat03"
                            name="burrito"
                            onChange={this.handleCategoryChange}
                            checked={this.state.selectedCategories.includes("burrito")}
                          />
                          <label for="cat03">Burrito <span class="ctgry-icon burrito"></span></label>
                        </div>
                               
                      </div>
                  </div>
                         
                <table className="vendor-table">
                   <tr>
                    <th>Product</th>
                    <th>Unit of Sale</th>
                    <th>Price</th>
                  </tr>
                      
                  {filteredProducts?.length > 0 ?(
                  filteredProducts.map((product) => (<tr>
                    <td>{`${product.productDescription} (${
                          product.productNumber.includes("_")
                            ? product.productNumber.substring(0, product.productNumber.indexOf("_"))
                            : product.productNumber
                        })`}   <a
                        className="title-tooltip title-right"
                        data-title={product.categoryId === "1"
                          ? "PIZZA"
                          : product.categoryId === "10" || product.categoryId === "13"
                            ? "PASTA, RICE & NOODLE SAUCES"
                            : "BURRITO"}
                      >
                        {/* <i className="fa fa-th-list" aria-hidden="true"></i> */}
                        <span className={`ctgry-icon ${
                            product.categoryId === "1"
                              ? "pizza"
                              : product.categoryId === "10" || product.categoryId === "13"
                              ? "pasta"
                              : "burrito"
                          }`}></span>
                      </a></td>
                    <td>{product.packageSize}</td>
                    <td>{this.priceFormat(Number(product.PDP_Price1))}</td>
                   
                  </tr>))):(<tr>
                      <td colSpan="3">No products found.</td>
                    </tr>)}
                  
                  
                </table>
              </div>
            </div>
          </>
        }
        closebtn={
          <>
            <span
              onClick={() => this.setState({ popupPricebandView: "",selectedCategories:['pizza','pasta','burrito'] })}
              className="close"
            ></span>
          </>
        }
      />);
   
    this.setState({ popupPricebandView: popupResult });
  }



  handleCategoryChange = (event) => {
    const category = event.target.name;
  
    this.setState(
      (prevState) => {
        let updatedCategories;
        if (prevState.selectedCategories.includes(category)) {
          updatedCategories = prevState.selectedCategories.filter(
            (item) => item !== category
          );
        } else {
          updatedCategories = [...prevState.selectedCategories, category];
        }
  
        return {
          selectedCategories: updatedCategories,
         
        }
      },
      () => {
        // Callback function to ensure the state has been updated
        this.viewProductsPopup(this.state.originalProducts);
        
      }
    );
  };


  componentDidMount() {
    this.getAccounts();
  }

  handleReset = () => {
    this.setState({
      searchTextVal: "",
      accounts: this.state.tempaccounts,
      showPopup: false,
      alertPopup: false,
    }, this.applyFilters);
  };

  handleChange = async (value) => {
    this.setState({
      searchTextVal: value,
      alertPopup: false,
      showPopup: false,
    }, this.applyFilters);
    this.filterData(value, "search");
  };

  handleReasonChange = async (value) => {
    this.setState({ reason: value, reasonError: false }, () => { });
  };

  handleStatusChange = (value) => {
    this.setState({ statusFilterVal: value }, this.applyFilters);
  };

  triggerSessionDelete = (customer) => {
    this.setState({ visibledeleteAlert: true, customeraccount: customer });

  };

  triggerDelete = () => {
    let URL = BaseUrl + `admin/deletesession/${this.state.customeraccount}`;

    this.setState({ visibledeleteAlert: false, loading: true });

    http
      .delete(URL)
      .then((response) => {
        this.setState({
          alertPopup: true,
          alertMessage:
            `Session has been deleted successfully for the account ${this.state.customeraccount}`,
          loading: false,

        });
      })
      .catch((err) => {
        console.log(err);
      });
  };


  applyFilters = () => {
    const { tempaccounts, searchTextVal, statusFilterVal } = this.state;
    let lowercasedText = searchTextVal.toLowerCase().trim();
    let lowercasedStatus = statusFilterVal.toLowerCase().trim();

    // Start from the full dataset
    let filteredData = tempaccounts;

    // Apply status filter if active
    if (lowercasedStatus) {
      filteredData = filteredData.filter((item) =>
        item.accountStatus && item.accountStatus.toLowerCase() === lowercasedStatus
      );
    }

    // Apply text filter if search text is entered
    if (lowercasedText) {
      filteredData = filteredData.filter((item) =>
        item.accountName.toLowerCase().includes(lowercasedText) ||
        item.accountCode.toLowerCase().includes(lowercasedText) ||
        item.accountEmail.toLowerCase().includes(lowercasedText) ||
        item.accountPriceListId.toLowerCase().includes(lowercasedText) ||
        item.Sectors?.toLowerCase().includes(lowercasedText)
      );
    }

    // Update state with final filtered results
    this.setState({ accounts: filteredData, searchResults: filteredData });
  };


  filterData = (value, filterType = "search") => {
    const lowercasedValue = value.toLowerCase().trim();
    let baseData = filterType === "status" && this.state.searchTextVal ? this.state.searchResults : this.state.tempaccounts;
    let filteredData = [];

    if (filterType === "status") {
      // Filtering by active/deactive status within search results or full dataset
      if (lowercasedValue === "active" || lowercasedValue === "deactive") {
        filteredData = baseData.filter((item) =>
          item.accountStatus && item.accountStatus.toString().toLowerCase() === lowercasedValue
        );
      } else {
        filteredData = baseData;
      }
    }
    else {
      // Search filter: applies text-based filtering on the full dataset
      filteredData = this.state.tempaccounts.filter((item) => {
        return (
          item.accountName.toLowerCase().includes(lowercasedValue) ||
          item.accountCode.toLowerCase().includes(lowercasedValue) ||
          item.accountEmail.toLowerCase().includes(lowercasedValue) ||
          item.accountPriceListId.toLowerCase().includes(lowercasedValue)||
          item.Sectors?.toLowerCase().includes(lowercasedValue)
        );
      });
      this.setState({ searchResults: filteredData });
    }

    this.setState({ accounts: filteredData });
  };

  handlesortbyChange = (value) => {
    this.setState({ sortBy: value, period: "",periodtext:"",showDatePicker: false, range: '', fromDate: '', toDate: '', alertPopup: false, alertMessage: "", }, () => {
      // Call API to get all records if 'sortBy' is 'all'
      if (value === "all") {
        this.getAccounts(); // Call the API function 
      }
    });
  };
  handlesortChange = (value, text) => {
    //console.log(text,'text');
    this.setState({ period: value,periodtext:text, showDatePicker: false });
    if (value === 'custom') {
      this.setState({ showDatePicker: true, range: value });
    } else {

      this.setState({ range: value, showDatePicker: false, fromDate: '', toDate: '' }, () => {
        const payload = {
          filterby: this.state.sortBy,
          period: value,
          datefrom: "",
          dateto: ""
        };
        this.callApi(payload);
      });
    }


  };

  handleFromDateChange = (date) => {
    this.setState({ fromDate: date }, this.callApiIfDatesSelected);
  };

  handleToDateChange = (date) => {
    this.setState({ toDate: date }, this.callApiIfDatesSelected
    );
  };
  DateChange = (value, type) => {
    if (type == "from") {
      this.setState({ fromDate: value, dateinvalid_error: false }, this.callApiIfDatesSelected);
    } else if (type == "to") {
      var dateval = new Date(this.state.fromDate);
      dateval = format(dateval, "yyyy-MM-dd");

      var dateval2 = new Date(value);
      dateval2 = format(dateval2, "yyyy-MM-dd");

      if (dateval > dateval2) {
        this.setState({ toDate: "", dateinvalid_error: true });
      } else {
        this.setState({ toDate: value, dateinvalid_error: false }, this.callApiIfDatesSelected);
      }
    }
  }

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        selectedFile: file,
        uploadMessage: "",
        showPopup: false,
      });
    }
  };

  handleUpload = (file) => {
    //const file = this.state.selectedFile;
    if (file) {
      const reader = new FileReader();
      this.setState({ isUploading: true });
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        var loginDetails = {};
        if (window.sessionStorage.getItem("authData")) {
          loginDetails = JSON.parse(window.sessionStorage.getItem("authData"));
        }

        if (sheets.length) {
          let rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          let URL = BaseUrl + "admin/account/import";

          const payload = {
            data: rows,
          };

          http
            .post(URL, payload)
            .then((response) => {
              this.getAccounts();
              this.setState({
                isUploading: false,
                uploadMessage: "Customers Imported successfully!",
                showcustomPopup: true,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  closeCustomPopup = () => {
    this.setState({ showcustomPopup: false, uploadMessage: "" });
  };

  importButtonClick = (value) => {
    this.setState({ popupImportView: true, importValue: value });
  };

  importClosepopup = (event, shouldUpload, file, importValue) => {
    if (shouldUpload && file) {

      this.uploadFile(file, importValue);
    }
    this.setState({
      popupImportView: false,
      importValue: "",
      selectedFile: null,
    });
  };

  uploadFile = (file, importValue) => {
    // Implement the actual upload logic here
    // For example, using FileReader and axios as discussed earlier
    if (importValue === "Customer") {
      this.handleUpload(file);
    }
  };

  callApiIfDatesSelected = () => {
    const { fromDate, toDate, sortBy } = this.state;
    if (fromDate && toDate) {
      // Close the date picker once both dates are selected
      this.setState({ showDatePicker: false });
      const payload = {
        filterby: sortBy,
        period: "custom",
        datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
        dateto: format(new Date(toDate), "yyyy-MM-dd")
      };
      this.callApi(payload);
    }
  };
  // Function to handle API call
  callApi = (payload) => {
    const URL = `${BaseUrl}admin/account`;
    this.setState({ loading: true }); // Optional loading state

    http.post(URL, payload)
      .then((response) => {
        this.setState({
          accounts: response.data.data,
          loading: false,
          tempaccounts: response.data.data,
          alertPopup: false,
          alertMessage: "",
        }, () => {
          // Call applyFilters after state is updated
          if (this.state.statusFilterVal || this.state.searchTextVal) {
            this.applyFilters();
          }
        }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertPopup: true,
          alertMessage: `The server is busy, please try again later.`,
          loading: false
        });
      });
  };

  render() {
    const { showDatePicker, sortBy, fromDate, toDate } = this.state;

    if (sessionStorage.getItem("authData")) {
      var loginemail = JSON.parse(sessionStorage.getItem("authData")).adminEmail;
    }

    const columns = [
      {
        name: "Account Name",
        selector: (row) => row.accountName,
        sortable: true,
      },
      {
        name: "Account Code",
        selector: (row) => row.accountCode,
        sortable: true,
      },
      {
        name: "Email Address",
        selector: (row) => row.accountEmail,
        sortable: false,
      },
      {
        name: "Price Band",
        selector: (row) => row.accountPriceListId,
        sortable: true,
      },
      {
        name: "Sectors",
        selector: (row) =>  row.Sectors? <a
        className="title-tooltip title-up"
        data-title={row.Sectors}
        >
        <i className="fa fa-industry" aria-hidden="true"></i>
      </a> : "N/A",
        sortable: true,
      },
      // {
      //   name: "Accept CWO Only",
      //   selector: (row) => row.accountPaymentMethod,
      //   sortable: true,
      // },
      {
        name: "Actions",
        selector: (row, index) => (
          <div>
            <a
              className="button title-tooltip title-up"
              data-title="View Details"
              style={{ color: "white" }}
              onClick={this.viewVendorDetails.bind(this, row)}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </a>
            {(loginemail === 'karthik@k2bsolutions.in' || loginemail === 'k2bsolutions@gmail.com') && (
              <>
              <a
              className="button title-tooltip title-up"
              data-title="Edit Details"
              style={{ color: "white" }}
              onClick={this.editVendorDetails.bind(this, row)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
            <a
              className="button title-tooltip title-up"
              data-title="Change Password "
              style={{ color: "white" }}
              onClick={this.changePassword.bind(this, row)}
            >
              <i className="fa fa-key"></i>
            </a>
            <a
              className="button title-tooltip title-up"
              data-title="View Products"
              style={{ color: "white" }}
              onClick={this.viewProducts.bind(this, row)}
            >
              <i className="fa fa-gbp" aria-hidden="true"></i>
            </a>
            <a
              className="button title-tooltip title-up"
              data-title="   Reset Password Email Trigger"
              style={{ color: "white" }}
              onClick={this.triggerEmail.bind(this, row)}
            >
              <i className="fa fa-envelope-o"></i>
            </a>
            </>
            )}
            <a
              className={`button title-tooltip title-up ${row.accountStatus === "active" ? "activate" : "deactivate"
                }`}
              data-title="Customer Activate/Deactivate"
              onClick={() => this.toggleCustomerStatus(row.accountCode)}
            >
              <i
                className={`fa ${row.accountStatus === "active" ? "fa-unlock" : "fa-lock"
                  }`}
              ></i>
            </a>
            <a
              className="button title-tooltip title-up"
              data-title="Customer Status Log"
              onClick={() => this.CustomerStatuslog(row.accountCode)}
            >
              <i className="fa fa-file-text-o"></i>
            </a>
            {(loginemail === 'karthik@k2bsolutions.in' || loginemail === 'k2bsolutions@gmail.com') && (<a
              className="button title-tooltip title-up"
              data-title="Delete Session"
              onClick={() => this.triggerSessionDelete(row.accountCode)}
            >
              <i className="fa fa-history"></i>
            </a>)}
          </div>
        ),

        sortable: false,
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: "25px",
        },
      },
      headCells: {
        style: {
          padding: "8px", // override the cell padding for head cells
        },
      },
      cells: {
        style: {
          padding: "8px", // override the cell padding for data cells
        },
      },
    };

    const paginationComponentOptions = {
      rowsPerPageText: "",
      rangeSeparatorText: "",
      selectAllRowsItem: true,
      selectAllRowsItemText: "",
      noRowsPerPage: "",
    };

    
    return (
      <>

        {this.state.alertPopup == true && (
          <CommonAlert title="Alert" message={this.state.alertMessage} />
        )}

        <div className="dashboard-block">
          <Sidebar />

          <div className="dash-left">
            <Header />

            <section className="cart-block">
              <div className="container">
                <div className="flex-title">
                  <h1 className="checkout-title">Customers</h1>
                  <div>
                  
                  {(loginemail === 'karthik@k2bsolutions.in' || loginemail === 'k2bsolutions@gmail.com') && 
                    (<button onClick={() => this.importButtonClick("Customer")}>
                      Customer Import
                    </button>)
                  }
                   
                  
                  </div>
                </div>

                <div className="right-table-outer-wrap vendors-table-wrap">
                  <div className="table-filter search-orders">
                    <div className="table-search-reset">
                      <div className="table-search">
                        <input
                          type="text"
                          value={this.state.searchTextVal}
                          onChange={(e) => this.handleChange(e.target.value)}
                          className="form-control"
                          placeholder="Search by Customer Details"
                        />
                      </div>
                      <button
                        className="submitbtn button search-cus-btn"
                        onClick={(e) =>
                          this.applyFilters
                        }
                      >
                        <i className="fa fa-search"></i>
                      </button>

                      <button
                        className="resetbtn"
                        type="reset"
                        onClick={this.handleReset}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="orders-dropdown vendor-select-drop">
                      <select
                        className="handle-srch"
                        value={sortBy}
                        onChange={(e) => this.handlesortbyChange(e.target.value)}
                      >
                        <option value="all">Show All Records</option> {/* Default option */}
                        <option value="Ordered">Ordered</option>
                        <option value="Not Ordered">Not Ordered</option>
                        <option value="lastLogin">Last Login</option>
                        <option value="createdAt">Created on</option>
                        <option value="updatedAt">Updated on</option>

                      </select>
                      {sortBy !== "all" && (<div className="dropdown-container"
                        onMouseEnter={() => {
                          if (this.state.period === "custom" && !this.state.showDatePicker) {
                            this.setState({ showDatePicker: true });
                          }
                        }}
                        onMouseLeave={() => {
                          if (this.state.period === "custom" && this.state.showDatePicker) {
                            this.setState({ showDatePicker: false });
                          }
                        }}>
                        <select
                          className="handle-srch"
                          value={this.state.period}
                          onChange={(e) => this.handlesortChange(e.target.value,e.target.options[e.target.selectedIndex].text)}
                        >
                          <option value="">
                            Select
                          </option>
                          <option value="30">Past 30 Days </option>
                          <option value="90">Past 90 Days</option>
                          <option value="180">Past 180 Days</option>
                          <option value="365">Past 1 Year</option>
                          <option value="custom">Custom Date</option>
                        </select>
                        {showDatePicker && (
                          <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                              selected={fromDate}
                              onChange={(date) => this.DateChange(date, "from")}
                              showDisabledMonthNavigation
                              dateFormat="dd/MM/yyyy"
                              disablePast={true}
                              maxDate={new Date()}
                              // endDate={toDate}
                              // maxDate={toDate} // Prevent selecting a "From" date after "To" date
                              placeholderText="Select From Date"
                            />
                            <label>To:</label>
                            <DatePicker
                              selected={toDate}
                              showDisabledMonthNavigation
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => this.DateChange(date, "to")}
                              disablePast={true}
                              maxDate={new Date()}
                              // endDate={toDate}
                              //minDate={fromDate} // Prevent selecting a "To" date before "From" date
                              placeholderText="Select To Date"
                            />
                          </div>
                        )}

                      </div>)}
                      <select
                        value={this.state.statusFilterVal}
                        className="handle-srch"
                        onChange={(e) => this.handleStatusChange(e.target.value)}
                      >
                        <option value="" disabled>
                          Status
                        </option>
                        <option value=" ">All</option>
                        <option value="active">Active</option>
                        <option value="deactive">Inactive</option>
                      </select>
                    </div>
                  </div>


                  <div className="table_overflow vendor-list-table">
                    {/* Display selected dates as text when DatePicker is hidden */}
                    {sortBy != 'all' && this.state.period != '' && this.state.loading === false && this.state.accounts.length > 0 && (
                      <div className="selected-dates">
                        <p className="admin-order account-total-records">
                          Total Records:{" "}
                          {this.state.accounts.length > 0
                            ? this.state.accounts.length
                            : ""}
                          {
                            fromDate && toDate ? (
                              <span>
                                {" "}
                                (From {this.formatDate(
                                  fromDate
                                )}{" "}
                                to {this.formatDate(toDate)})
                              </span>
                            ) :  this.state.periodtext && this.state.periodtext!='Custom Date' ? (
                              ` (${this.state.periodtext})`
                            ) : (
                              ""
                            )
                          }
                        </p>
                      </div>
                    )}

                    <DataTable
                      columns={columns}
                      noDataComponent={
                        <div className="no-records-found">
                          Sorry, no customers were matching your keyword, try a
                          new search.
                        </div>
                      }
                      data={this.state.accounts}
                      customStyles={customStyles}
                      pagination
                      paginationPerPage={25}
                      progressPending={this.state.loading}
                      paginationRowsPerPageOptions={[25, 50, 75, 100]}
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
          {this.state.showDialog && (
            <div className="custom-alert-pop paylater-popup">
              <div className="custom-alert-container">
                <div className="custom-alert-wrap">
                  <div className="custom-alert-header">
                    <h4>Alert</h4>
                  </div>
                  <div className="custom-alert-body">
                    <p>
                      Are you sure you want to{" "}
                      {this.state.confirmationAction.action} the customer?
                    </p>
                    <div className="alert-buttons">
                      <button
                        onClick={this.confirmActionHandler}
                        className="popup-alert-btn button"
                      >
                        Yes
                      </button>
                      <button
                        onClick={this.hideConfirmationDialog}
                        className="popup-alert-btn button"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.showReasonPopup && (
            <div className="custom-alert-pop paylater-popup">
              <div className="custom-alert-container">
                <div className="custom-alert-wrap">
                  <div className="custom-alert-header">
                    <h4>{this.state.confirmationAction.action}</h4>
                  </div>
                  <div className="custom-alert-body">
                    <div className="input-field">
                      <span className="labeltxt">Post your comments</span>
                      <textarea
                        name="reason"
                        onChange={(e) =>
                          this.handleReasonChange(e.target.value)
                        }
                      ></textarea>
                      {this.state.reasonError && (
                        <div className="err-msg">Please update some reason</div>
                      )}
                    </div>

                    <div className="alert-buttons">
                      <button
                        onClick={this.showConfirmationDialog}
                        className="popup-alert-btn button"
                      >
                        Submit
                      </button>
                      <button
                        onClick={this.hideConfirmationDialog}
                        className="popup-alert-btn button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.showcustomPopup && (
            <div className="custom-alert-pop paylater-popup">
              <div className="custom-alert-container">
                <div className="custom-alert-wrap">
                  <div className="custom-alert-header">
                    <h4>Alert</h4>
                  </div>
                  <div className="custom-alert-body">
                    <p>{this.state.uploadMessage}</p>

                    <div className="alert-buttons">
                      <button
                        onClick={this.closeCustomPopup}
                        className="popup-alert-btn button"
                      >
                        ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.popupView}
          {this.state.popuplogView}
          {this.state.popupPricebandView}
          {this.state.editvendor && (
            <EditvendorDetails
              VendorDetails={this.state.VendorDetails}
              popupclose={this.editclosepopup}
              editvendorDetails={this.ediVendorsdetails}
            />
          )}
          {this.state.showPopup && (
            <ChangePassword
              popupclose={this.closepopup}
              emailadd={this.state.Vendoremailadd}
              accCode={this.state.Vendoraccountcode}
            />
          )}

          {this.state.popupImportView && (
            <Importpopup
              closePopup={this.importClosepopup}
              impValue={this.state.importValue}
            />
          )}
          {this.state.isUploading && (
            <div className="loading-overlay">
              <div className="loading-message">Importing...</div>
            </div>
          )}
        </div>
        {this.state.visibleAlert === true && (
          <div className="custom-alert-pop paylater-popup">
            <div className="custom-alert-container">
              <div className="custom-alert-wrap">
                <div className="custom-alert-header">
                  <h4>Alert</h4>
                </div>
                <div className="custom-alert-body">
                  <p>Are you sure you want to send reset password link?</p>

                  <div className="alert-buttons">
                    <a
                      onClick={this.triggerMail.bind(this)}
                      className="popup-alert-btn button"
                    >
                      OK
                    </a>
                    <a
                      onClick={this.alertPopupclose.bind(this)}
                      className="popup-alert-btn button"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.visibledeleteAlert === true && (
          <div className="custom-alert-pop paylater-popup">
            <div className="custom-alert-container">
              <div className="custom-alert-wrap">
                <div className="custom-alert-header">
                  <h4>Delete Session</h4>
                </div>
                <div className="custom-alert-body">
                  <p>Are you sure you want to delete the session for {" "} {this.state.customeraccount}?</p>

                  <div className="alert-buttons">
                    <a
                      onClick={this.triggerDelete.bind(this)}
                      className="popup-alert-btn button"
                    >
                      OK
                    </a>
                    <a
                      onClick={this.DeletePopupclose.bind(this)}
                      className="popup-alert-btn button"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Vendors;

import React, { Component } from "react";

import Header from "../common/layout/header";
import Footer from "../common/layout/footer";
import CommonAlert from "../common/Commonalert";
import { http } from "../helper";
import { read, utils } from "xlsx";
import { BaseUrl } from "../common/config";
import NoImage from "../assets/images/no-image.jpg";
import Sidebar from "../common/sidebar";
import DataTable from "react-data-table-component";
import Popup from "../common/popup";
import EditProductDetails from "./editProductDetails";
import Importpopup from "../common/importpopup";
import {
  dateformatwithuktime,
  capitalizeFirstLetter,
} from "../utilityfunctions";

class Products extends Component {
  constructor(props) {
    super(props);
    this.showConfirmationDialog = this.showConfirmationDialog.bind(this);
    this.showReasonDialog = this.showReasonDialog.bind(this);
    this.state = {
      loading: true,
      products: [],
      priceCode: "PL1",
      tempproducts: [],
      searchTextVal: "",
      categoryVal: "",
      viewImage: "",
      productImageIndex: "",
      popupView: "",
      filterByValue: "",
      isElementVisible: false,
      isElementVisibleMobile: false,
      filterSelectedValue: [],
      filterValueList: [],
      activeProducts: [],
      isActive: [],
      filterCount: {},
      showDialog: false,
      showReasonPopup: false,
      confirmationAction: "",
      filterCounts: {},
      editProdDet: false,
      ProductDet: {},
      alertMessage: "",
      alertPopup: false,
      showpdfViewer: false,
      pdfURL: "",
      ShowConfirm: false,
      popuplogView: "",
      reason: "",
      filterapply: false,
      tempFilterSelectedValue: [],
      groupFilterSelectedValue:[],
      taxFilterSelectedValue:[],
      tempFilterCounts: {},
      selectedFile: null,
      isUploading: false,
      uploadMessage: "",
      showPopup: false,
      PrdpriceselectedFile: null,
      isPrdpriceUploading: false,
      popupImportView: false,
      importValue: "",
      selectedStatus: " ",
      moreFilter:false,
      taxFilterList:[],
    };

    if (!sessionStorage.getItem("isLoggedIn")) window.location.href = "/";

    if (
      JSON.parse(sessionStorage.getItem("authData")) != "" &&
      !JSON.parse(
        sessionStorage.getItem("authData")
      ).adminRole.permissions.includes("manage_products")
    ) {
      window.location.href = "/unauthorized";
    }

    this.dropdownRef = React.createRef();
    this.dropdownMobileRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.fileInputPrdpriceRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickOutsideMobile = this.handleClickOutsideMobile.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutsideMobile);
    this.getProducts(this.state.priceCode);
    this.getAllergenlist();
    this.getIngredientslist();
    //this.getSettings();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutsideMobile);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isElementVisible: false });
    }
  };

  handleClickOutsideMobile = (event) => {
    // Ensure that this.dropdownMobileRef is not null before accessing its properties
    if (
      this.dropdownMobileRef &&
      this.dropdownMobileRef.current &&
      !this.dropdownMobileRef.current.contains(event.target)
    ) {
      this.setState({ isElementVisibleMobile: false });
    }
  };

  priceFormat = (price) => {
    
    return price.toLocaleString("en-US", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
    });
};

  getAllergenlist() {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/allergenlist";
    http
      .get(URL)
      .then((response) => {
        let responseData = response.data.data;
        var allergendata = [];
        responseData.map((item) => {
          allergendata.push(item.allergenTitle);
        
        });
     
        this.setState({ allergens: allergendata });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getIngredientslist() {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/ingredientlist";
    http
      .get(URL)
      .then((response) => {
        let responseData = response.data.data;
        var ingredientsdata = [];
        responseData.map((item) => {
          ingredientsdata.push(item.ingredientTitle);
        });

        this.setState({ ingredientsHigh: ingredientsdata });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleImage = (item) => {
    let viewdddImage = "";

    //if(item.productImage !== '' && item.productImage !== null){

    viewdddImage = (
      <Popup
        extClass="prt-poup prod-img-pop show"
        closebtn={
          <>
            <span
              onClick={() => this.setState({ viewImage: "" })}
              className="close"
            ></span>
          </>
        }
        headMoreTxt={
          <>
            <div className="order-top">
              <h4>
                <span>
                  {item.productDescription} ({item.productNumber})
                </span>
              </h4>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="prt-bdy-img">
              <img
                src={
                  item.productImage !== "" && item.productImage !== null
                    ? item.productImage
                    : NoImage
                }
                alt="Product"
              />
              <div className="prt-dsc">
                <span className="pop-prt-txt">{item.productName}</span>
              </div>
            </div>
          </>
        }
      />
    );
    // this.setState({ productImageIndex: e });
    this.setState({ viewImage: viewdddImage });
    // }
  };

  hightLightIngredients(str) {
    this.state.ingredientsHigh.forEach((substr) => {
      var strRegExp = new RegExp("\\b" + substr + "\\b", "ig");

      str = str.replaceAll(strRegExp, "<strong>" + substr + "</strong>");
    });

    return <div dangerouslySetInnerHTML={{ __html: str }} />;
  }

  highlightAllergens(str) {
    this.state.allergens.forEach((substr) => {
      var strRegExp = new RegExp("\\b" + substr + "\\b", "ig");

      str = str.replaceAll(strRegExp, "<strong>" + substr + "</strong>");
    });

    return <div dangerouslySetInnerHTML={{ __html: str }} />;
  }

  editProduct = (ProductDet) => {
    this.setState({ editProdDet: true, ProductDet: ProductDet });
  };

  editclosepopup = (alrtpopup, alrtmsg) => {
    this.setState({
      editProdDet: false,
      alertPopup: alrtpopup,
      alertMessage: alrtmsg,
    });
  };

  editPrdDetails = async (formData) => {
    let URL = BaseUrl + "admin/editproductdetail/" + formData.Pid;

    this.setState({ loading: true });

    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "prodImage" || key === "prodSpec") {
        // Append file data if present
        if (formData[key]) {
          data.append(key, formData[key]);
        }
      } else {
        // Append other form fields
        data.append(key, formData[key]);
      }
    });

    try {
      const response = await http.put(URL, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    } finally {
      this.setState({
        alertPopup: true,
        alertMessage: "Product Details Updated Successfully",
        loading: false,
        editProdDet: false,
      });
    }
  };

  handlespecClick = (productSpec) => {
    const pdfName = productSpec.split("/").pop(); // Extracts the PDF name from the URL
    let URL = "";

    const pdfUrl = `${BaseUrl}getpdf/auth/${pdfName}`;

    sessionStorage.setItem("pdfUrl", pdfUrl);
    sessionStorage.setItem(
      "token",
      sessionStorage.getItem("authData")
        ? JSON.parse(sessionStorage.getItem("authData")).token
        : ""
    );
    const newTabUrl = `${window.location.origin}/PdfViewer`;
    const newTab = window.open(newTabUrl, "_blank");
  };

  viewProductDetails(prd_det) {
    let popupResult = (
      <Popup
        extClass="info-poup show"
        closebtn={
          <>
            <span
              onClick={() => this.setState({ popupView: "" })}
              className="close"
            ></span>
          </>
        }
        headMoreTxt={
          <>
            <div className="order-top">
              <h4>
                <span>
                  {prd_det.productDescription} - {prd_det.productNumber}
                </span>
              </h4>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="info-bdy">
              {prd_det.packageSize !== null && prd_det.packageSize !== "" && (
                <div className="info-item">
                  <div className="info-innr">
                    <h5>
                      <span>Unit of Sale</span>
                    </h5>
                    <p>{prd_det.packageSize}</p>
                  </div>
                </div>
              )}

              {prd_det.productIngredient !== null &&
                prd_det.productIngredient !== "" && (
                  <div className="info-item">
                    <div className="info-innr">
                      <h5>
                        <span>Ingredients</span>
                      </h5>
                      <p>
                        {this.hightLightIngredients(prd_det.productIngredient)}
                      </p>
                    </div>
                  </div>
                )}
              {prd_det.productSpec !== null && prd_det.productSpec !== "" && (
                <div className="info-item">
                  <div className="info-innr">
                    <h5>
                      <span>Product Specification</span>
                    </h5>
                    <p>
                      <a href={prd_det.productSpec} target="_blank">
                        Click here{" "}
                      </a>{" "}
                      for Information on ingredients not included in the 14
                      allergens, nutritional values, usage instructions and
                      storage/shelf life
                    </p>
                    {/* <a onClick={() => this.handlespecClick(prd_det.productSpec)}>Click here </a> for Information on ingredients not included in the 14 allergens, nutritional values, usage instructions and storage/shelf life                                
                                                                    </p> */}
                  </div>
                </div>
              )}
              {prd_det.productUSP !== null && prd_det.productUSP !== "" && (
                <div className="info-item">
                  <div className="info-innr">
                    <h5>
                      <span>Allergens</span>
                    </h5>
                    <p>
                      {prd_det.productUSP.toLowerCase().trim() ===
                      "no allergens"
                        ? "Doesn't contain any of the 14 most potent and prevalent allergens required to be declared by food law"
                        : this.highlightAllergens(prd_det.productUSP)}
                    </p>
                  </div>
                </div>
              )}
              {prd_det.productDiet !== null && prd_det.productDiet !== "" && (
                <div className="info-item">
                  <div className="info-innr">
                    <h5>
                      <span>Diet Suitablity</span>
                    </h5>
                    <p>{prd_det.productDiet}</p>
                  </div>
                </div>
              )}
            </div>
          </>
        }
      />
    );

    this.setState({ popupView: popupResult });
  }

  viewPricebandDetails(prd_det) {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }
   
    let URL = BaseUrl + `admin/productband/${prd_det.productNumber}`;
    var PricebandData = [];
    http
      .get(URL)
      .then((response) => {
        
        let responsePricebandData = response.data.data;
        responsePricebandData.map((item) => {
          PricebandData.push({
            reference: item.PDP_Reference,
            price: item.PDP_Price1,
          });
          
        });
        this.setState({ PriceBand: PricebandData });

        this.viewPricebandPopup(PricebandData, prd_det);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  viewPricebandPopup(PricebandData, prd_det) {
    let popupResult = (
      <Popup
        extClass="info-poup show"
        closebtn={
          <>
            <span
              onClick={() => this.setState({ pricebandView: "" })}
              className="close"
            ></span>
          </>
        }
        headMoreTxt={
          <>
            <div className="order-top">
              <h4>
                <span>
                  {prd_det.productDescription} - {prd_det.productNumber}
                </span>
              </h4>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="info-bdy">
              <div className="info-item">
                <div className="info-innr">
                 
                  <div className="vendor-table-view">
                    <table className="vendor-table dtp-price-table">
                      <tr>
                        <th>Price Band</th>
                        <th>Price</th>
                      </tr>

                      {PricebandData != "" && PricebandData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.reference}</td>
                          <td>{this.priceFormat(Number(item.price))}</td>
                        </tr>
                      ))}
                      {PricebandData == "" && <tr key={index}>
                          <td colSpan={2}>"No Price Bands Available"</td>
                         
                        </tr> }
                      
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    );

    this.setState({ pricebandView: popupResult });
  }

  getProducts(priceCode) {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = `${BaseUrl}admin/productprice/${priceCode}`;
    this.setState({ loading: true });

    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    http
      .get(URL)
      .then((response) => {
        let responseData = response.data.data;
        let duplicateResponse = response.data.data;

        duplicateResponse.sort(this.dynamicSort("ProductGroup"));

        var filterList = [];
       
        duplicateResponse.map((item) => {
          if (item.SuitableFor !== "" && item.SuitableFor !== null) {
            filterList.push(item.SuitableFor);
          }
        });

        var taxcodeFilterList = [...new Set(
          duplicateResponse
            .filter(item => item.productTaxCode)
            .map(item => item.productTaxCode)
        )];

        var duplicateFilterRemove = filterList.filter(
          (item, index) => filterList.indexOf(item) === index
        );

        var productGroupList = [];

        duplicateResponse.map((item) => {
          if (item.ProductGroup !== "" && item.ProductGroup !== null) {
            productGroupList.push(item.ProductGroup);
          }
        });

        var duplicateGroupRemove = productGroupList.filter(
          (item, index) => productGroupList.indexOf(item) === index
        );

        let groupProductsList = [];

        duplicateGroupRemove.map((item) => {
          let productGroup = [];
          duplicateResponse.map((product) => {
            if (item == product.ProductGroup) {
              productGroup.push(product);
            }
          });

          if (productGroup.length > 0) {
            groupProductsList.push({ [item]: productGroup });
          }
        });
        let activeProducts = [];
        let inactiveProducts = [];
        this.setState({
          products: responseData,
          activeProducts: activeProducts,
          inactiveProducts: inactiveProducts,
          products: duplicateResponse,
          filterValueList: duplicateFilterRemove,
          tempproducts: duplicateResponse,
          groupProductsList: groupProductsList,
          groupFilterList: duplicateGroupRemove,
          taxFilterList:taxcodeFilterList,
        });
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1 && b[property] !== null) {
        return b[property].localeCompare(a[property]);
      } else if (a[property] !== null) {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  handleReset = () => {
    const { previousFilteredProducts, filterapply, tempproducts } = this.state;
   // Check if filters were applied previously
    let productsToSearch = filterapply ? previousFilteredProducts : tempproducts;
    this.setState({ searchTextVal: "", products: productsToSearch });
  };

  handleChange = async (value) => {
    this.setState({ searchTextVal: value });
    this.setState({ categoryVal: "" });
    this.filterData('search',value);
  };

  handlefilterChange = async (value) => {
    this.setState(
      { selectedStatus: value }, 
      () => this.applyFilter()  // Call applyFilter after state is updated
    );
    this.filterData('status',value);
  };

  handleReasonChange = (e) => {
    // if(e.target.value!==""){
    this.setState({ reason: e.target.value, reasonError: false });
    // }
  };

  filterData = async (filterType,value) => {
    let tempproducts = this.state.tempproducts;
     const lowercasedValue = value.toLowerCase().trim();
     const { tempFilterSelectedValue, groupFilterSelectedValue, taxFilterSelectedValue } = this.state;
        let result = [];
        if (filterType === 'suitablefor') {
          const newSelectedValues = tempFilterSelectedValue.includes(lowercasedValue)
            ? tempFilterSelectedValue.filter(item => item !== lowercasedValue)
            : [...tempFilterSelectedValue, lowercasedValue];
          this.setState({ tempFilterSelectedValue: newSelectedValues });
        }

        // For "Product Group" filters
      if (filterType === 'groupfilter') {
        const newGroupValues = groupFilterSelectedValue.includes(lowercasedValue)
          ? groupFilterSelectedValue.filter(item => item !== lowercasedValue)
          : [...groupFilterSelectedValue, lowercasedValue];
        this.setState({ groupFilterSelectedValue: newGroupValues });
      }

      // For "Tax Code" filters
      if (filterType === 'taxfilter') {
        const newTaxValues = taxFilterSelectedValue.includes(lowercasedValue)
          ? taxFilterSelectedValue.filter(item => item !== lowercasedValue)
          : [...taxFilterSelectedValue, lowercasedValue];
        this.setState({ taxFilterSelectedValue: newTaxValues });
      }

       // Determine if filters are already applied
      const isFilterApplied = tempFilterSelectedValue.length > 0 || groupFilterSelectedValue.length > 0 || taxFilterSelectedValue.length > 0 || this.state.selectedStatus != ' ';

      // If a search is triggered, decide where to search based on the applied filters
      let productsToSearch = isFilterApplied ? this.state.filteredProducts : tempproducts;

      if(filterType === 'search')
      {
        result = productsToSearch.filter(item => {
          const searchValue = lowercasedValue;
          return ["productDescription", "productNumber", "productUSP", "productIngredient"].some(key => 
              item[key]?.toString().toLowerCase().includes(searchValue)
          );
      });
      this.setState({ products: result });
      }

   
  };

  openFilterOption = () => {
    this.setState({moreFilter:true});
  };

  closeFilterOption = () => {
    this.setState({moreFilter:false,tempFilterSelectedValue: [],
      groupFilterSelectedValue: [],
      taxFilterSelectedValue:[]});
  };

 
  removeFilterpop = (event, value) => {
    this.setState((prevState) => {
      // Remove the selected filter value from SuitableFor (tempFilterSelectedValue)
      const updatedTempFilterSelectedValue = prevState.tempFilterSelectedValue.filter(
        (filter) => filter !== value
      );
  
      // Remove the selected filter value from Product Group or Tax Code if necessary
      const updatedGroupFilterSelectedValue = prevState.groupFilterSelectedValue.filter(
        (filter) => filter !== value
      );
      const updatedTaxFilterSelectedValue = prevState.taxFilterSelectedValue.filter(
        (filter) => filter !== value
      );
  
      // Reapply the filters to get the filtered products
      let filteredProducts = prevState.tempproducts;
  
      // Reapply the "Suitable For" filter
      if (updatedTempFilterSelectedValue.length > 0) {
        filteredProducts = filteredProducts.filter(product =>
          updatedTempFilterSelectedValue.includes(product.SuitableFor.toLowerCase())
        );
      }
  
      // Reapply the "Product Group" filter
      if (updatedGroupFilterSelectedValue.length > 0) {
        filteredProducts = filteredProducts.filter(product =>
          updatedGroupFilterSelectedValue.includes(product.ProductGroup.toLowerCase())
        );
      }
  
      // Reapply the "Tax Code" filter
      if (updatedTaxFilterSelectedValue.length > 0) {
        filteredProducts = filteredProducts.filter(product =>
          updatedTaxFilterSelectedValue.includes(product.productTaxCode.toLowerCase())
        );
      }
  
      // Reapply the "Status" filter if necessary
      if (prevState.selectedStatus !== ' ') {
        filteredProducts = filteredProducts.filter(product =>
          product.PDP_Status.toLowerCase() === prevState.selectedStatus.toLowerCase().trim()
        );
      }
  
      // Update the filter counts based on the re-filtered products
      const updatedFilterCounts = {};
  
      // Suitable For Counts
      updatedTempFilterSelectedValue.forEach((filter) => {
        updatedFilterCounts[filter] = filteredProducts.filter(
          (item) => item.SuitableFor && item.SuitableFor.toLowerCase().trim() === filter.toLowerCase().trim()
        ).length;
      });
  
      // Product Group Counts
      updatedGroupFilterSelectedValue.forEach((filter) => {
        updatedFilterCounts[filter] = filteredProducts.filter(
          (item) => item.ProductGroup && item.ProductGroup.toLowerCase().trim() === filter.toLowerCase().trim()
        ).length;
      });
  
      // Tax Code Counts
      updatedTaxFilterSelectedValue.forEach((filter) => {
        updatedFilterCounts[filter] = filteredProducts.filter(
          (item) => item.productTaxCode && item.productTaxCode.toLowerCase().trim() === filter.toLowerCase().trim()
        ).length;
      });
  
      return {
        tempFilterSelectedValue: updatedTempFilterSelectedValue,
        groupFilterSelectedValue: updatedGroupFilterSelectedValue,
        taxFilterSelectedValue: updatedTaxFilterSelectedValue,
        filterSelectedValue: [...updatedTempFilterSelectedValue, ...updatedGroupFilterSelectedValue, ...updatedTaxFilterSelectedValue],
        //products: filteredProducts,
        filteredProducts: filteredProducts,
        //filterCounts: updatedFilterCounts,
        //filterapply: updatedTempFilterSelectedValue.length > 0 || updatedGroupFilterSelectedValue.length > 0 || updatedTaxFilterSelectedValue.length > 0,
      };
    });
  };

  removeFilter = (event, value) => {
    this.setState((prevState) => {
      // Remove the selected filter value from SuitableFor (tempFilterSelectedValue)
      const updatedTempFilterSelectedValue = prevState.tempFilterSelectedValue.filter(
        (filter) => filter !== value
      );
  
      // Remove the selected filter value from Product Group or Tax Code if necessary
      const updatedGroupFilterSelectedValue = prevState.groupFilterSelectedValue.filter(
        (filter) => filter !== value
      );
      const updatedTaxFilterSelectedValue = prevState.taxFilterSelectedValue.filter(
        (filter) => filter !== value
      );
  
      // Reapply the filters to get the filtered products
      let filteredProducts = prevState.tempproducts;
  
      // Reapply the "Suitable For" filter
      if (updatedTempFilterSelectedValue.length > 0) {
        filteredProducts = filteredProducts.filter(product =>
          updatedTempFilterSelectedValue.includes(product.SuitableFor.toLowerCase())
        );
      }
  
      // Reapply the "Product Group" filter
      if (updatedGroupFilterSelectedValue.length > 0) {
        filteredProducts = filteredProducts.filter(product =>
          updatedGroupFilterSelectedValue.includes(product.ProductGroup.toLowerCase())
        );
      }
  
      // Reapply the "Tax Code" filter
      if (updatedTaxFilterSelectedValue.length > 0) {
        filteredProducts = filteredProducts.filter(product =>
          updatedTaxFilterSelectedValue.includes(product.productTaxCode.toLowerCase())
        );
      }
  
      // Reapply the "Status" filter if necessary
      if (prevState.selectedStatus !== ' ') {
        filteredProducts = filteredProducts.filter(product =>
          product.PDP_Status.toLowerCase() === prevState.selectedStatus.toLowerCase().trim()
        );
      }
  
      // Update the filter counts based on the re-filtered products
      const updatedFilterCounts = {};
  
      // Suitable For Counts
      updatedTempFilterSelectedValue.forEach((filter) => {
        updatedFilterCounts[filter] = filteredProducts.filter(
          (item) => item.SuitableFor && item.SuitableFor.toLowerCase().trim() === filter.toLowerCase().trim()
        ).length;
      });
  
      // Product Group Counts
      updatedGroupFilterSelectedValue.forEach((filter) => {
        updatedFilterCounts[filter] = filteredProducts.filter(
          (item) => item.ProductGroup && item.ProductGroup.toLowerCase().trim() === filter.toLowerCase().trim()
        ).length;
      });
  
      // Tax Code Counts
      updatedTaxFilterSelectedValue.forEach((filter) => {
        updatedFilterCounts[filter] = filteredProducts.filter(
          (item) => item.productTaxCode && item.productTaxCode.toLowerCase().trim() === filter.toLowerCase().trim()
        ).length;
      });
  
      return {
        tempFilterSelectedValue: updatedTempFilterSelectedValue,
        groupFilterSelectedValue: updatedGroupFilterSelectedValue,
        taxFilterSelectedValue: updatedTaxFilterSelectedValue,
        filterSelectedValue: [...updatedTempFilterSelectedValue, ...updatedGroupFilterSelectedValue, ...updatedTaxFilterSelectedValue],
        products: filteredProducts,
        filteredProducts: filteredProducts,
        filterCounts: updatedFilterCounts,
        //filterapply: updatedTempFilterSelectedValue.length > 0 || updatedGroupFilterSelectedValue.length > 0 || updatedTaxFilterSelectedValue.length > 0,
      };
    });
  };

  clearFilterSearch() {
    this.setState({
      products: this.state.tempproducts,
      filterSelectedValue: [],
      selectedStatus:' ',
      tempFilterSelectedValue: [],
      groupFilterSelectedValue: [],
      taxFilterSelectedValue: [],
      filterapply: false,
      moreFilter:false
    });
  }

  applyFilter() {
    const { tempFilterSelectedValue,groupFilterSelectedValue,taxFilterSelectedValue,tempproducts } = this.state;
    let filteredProducts = tempproducts;
        

  // Start by filtering based on the SuitableFor
  filteredProducts = tempproducts.filter(product =>
    tempFilterSelectedValue.length === 0 ||
    tempFilterSelectedValue.includes(product.SuitableFor.toLowerCase())
  );

  // Then filter based on "Product Group"
  filteredProducts = filteredProducts.filter(product =>
    groupFilterSelectedValue.length === 0 ||
    groupFilterSelectedValue.includes(product.ProductGroup.toLowerCase())
  );

  // Then filter based on "Tax Code"
  filteredProducts = filteredProducts.filter(product =>
    taxFilterSelectedValue.length === 0 ||
    taxFilterSelectedValue.includes(product.productTaxCode.toLowerCase())
  );

  if(this.state.selectedStatus != ' ')
    {
      filteredProducts = filteredProducts.filter((product) =>
       
          product.PDP_Status.toLowerCase() === this.state.selectedStatus.toLowerCase().trim()
      
      );
    }
    
  // Update the filter counts based on the selected filters
  const updatedFilterCounts = {};

    // Update the filter counts for "Suitable For" filters
  tempFilterSelectedValue.forEach((filter) => {
    updatedFilterCounts[filter] = filteredProducts.filter(
      (item) => item.SuitableFor && item.SuitableFor.toLowerCase().trim() === filter.toLowerCase().trim()
    ).length;
  });

  // Update the filter counts for "Product Group" filters
  groupFilterSelectedValue.forEach((filter) => {
    updatedFilterCounts[filter] = filteredProducts.filter(
      (item) => item.ProductGroup && item.ProductGroup.toLowerCase().trim() === filter.toLowerCase().trim()
    ).length;
  });

  // Update the filter counts for "Tax Code" filters
  taxFilterSelectedValue.forEach((filter) => {
    updatedFilterCounts[filter] = filteredProducts.filter(
      (item) => item.productTaxCode && item.productTaxCode.toLowerCase().trim() === filter.toLowerCase().trim()
    ).length;
  });

    this.setState({
      products: filteredProducts,
      filterSelectedValue:  [...tempFilterSelectedValue, ...groupFilterSelectedValue, ...taxFilterSelectedValue],
      filterCounts: updatedFilterCounts,
      filterapply: true,
      filteredProducts:filteredProducts,
      previousFilteredProducts: filteredProducts,  
      moreFilter:false
      //selectedStatus:' ',
    });
  }

  showConfirmationDialog = () => {
    if (this.state.reason !== "") {
      this.setState({ showReasonPopup: false, showDialog: true }, () => {});
    } else {
      this.setState({ reasonError: true });
    }
  };

  showReasonDialog = (productNumber, action) => {
    this.setState(
      {
        showReasonPopup: true,
        reasonError: false,
        confirmationAction: { productNumber, action },
      },
      () => {}
    );
  };

  hideConfirmationDialog = () => {
    this.setState({
      showDialog: false,
      showReasonPopup: false,
      reasonError: false,
      reason: "",
    });
  };

  handleContextMenu = (event) => {
    event.preventDefault();
  };

  ProductStatuslog = (productNumber, product_description) => {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/productlog/" + productNumber;

    http
      .get(URL)
      .then(async (response) => {
        let responseData = [];
        responseData = response.data.data;
        this.viewlogDetails(responseData, product_description, productNumber);
      })
      .catch((error) => {
        console.error("Error updating customer status:", error);
      });
  };
  formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
  viewlogDetails(prd_det, product_description, productNumber) {
    let popupResult = (
      <Popup
        extClass="info-poup show"
        closebtn={
          <>
            <span
              onClick={() => this.setState({ popuplogView: "" })}
              className="close"
            ></span>
          </>
        }
        headMoreTxt={
          <>
            <div className="order-top">
              <h4>
                <span>
                  {product_description} - {productNumber}
                </span>
              </h4>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="bottom vendor-details prd-log">
              {prd_det && prd_det.length > 0 ? (
                prd_det.map((prd_item) => {
                  const [prdstatus, notes] = prd_item.noteText.split(/-(.+)/);
                  
                  let prdactionstatus =
                    prdstatus.trim() === "activate"
                      ? "Activated"
                      : "Deactivated";
                  let createdby = prd_item.createdBy ? (
                    <>
                      <span className="log-user">{prd_item.createdBy}</span>
                      <span className="log-line">|</span>
                    </>
                  ) : (
                    ""
                  );
                  return (
                    <div className="info-item">
                      <div className="info-innr">
                        <div className="flex-log-hdr">
                          <p>
                            <span className="labeltxt acn-by">
                              {prdactionstatus}
                            </span>
                          </p>
                          <div className="hdrlog-rit">
                            {createdby}
                            <span className="log-date">
                              {dateformatwithuktime(prd_item.createdAt)}
                            </span>{" "}
                          </div>
                        </div>
                        <p>
                          <span className="labeltxt log-reason">Reason: </span>
                          {notes ? notes.trim() : ""}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-logs">No logs available</div>
              )}
            </div>
          </>
        }
      />
    );

    this.setState({ popuplogView: popupResult });
  }

  toggleProductStatus = (productNumber) => {
    const { products } = this.state;
    const product = products.find((p) => p.productNumber === productNumber);
    const isCurrentlyActive = product.PDP_Status;
    const action = isCurrentlyActive === "activate" ? "deactivate" : "activate";
    this.showReasonDialog(productNumber, action);
  };

  confirmActionHandler = () => {
    const { productNumber, action } = this.state.confirmationAction;
    this.confirmAction(productNumber, action);
    this.hideConfirmationDialog();
  };
  confirmAction = (productNumber, action) => {
    const { confirmationAction } = this.state;
    const { products, isActive, reason } = this.state;
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "product/status";
    const payload = {
      PDP_ProductNumber: productNumber,
      action: action,
    };

    http
      .put(URL, payload)
      .then(async (response) => {
        if (response.status === 200) {
          this.setState((prevState) => {
            const updatedProducts = prevState.products.map((product) =>
              product.productNumber === productNumber
                ? { ...product, PDP_Status: payload.action }
                : product
            );
            const updatedtempProducts = prevState.tempproducts.map((product) =>
              product.productNumber === productNumber
                ? { ...product, PDP_Status: payload.action }
                : product
            );
            return {
              products: updatedProducts,
              tempproducts: updatedtempProducts,
            };
          });
          let noteURL = BaseUrl + "admin/productlog";
          const notepayload = {
            productNumber: productNumber,
            noteText: action + " - " + reason,
            createdBy: loginDetails.adminName,
          };
          http.post(noteURL, notepayload).then(async (response) => {
            const actionMsg =
              action.trim() === "activate" ? "activated" : "deactivated";
            this.setState({
              showPopup: true,
              uploadMessage: `Product has been ${actionMsg} successfully!`,
            });
          });

          this.hideConfirmationDialog();
        }
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
        alert("Failed to update product status.");
      });
  };

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };

  handlePriceButtonClick = () => {
    this.fileInputPrdpriceRef.current.click();
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        selectedFile: file,
        uploadMessage: "",
        showPopup: false,
      });
    }
  };
  handlePrdpriceFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        PrdpriceselectedFile: file,
        uploadMessage: "",
        showPopup: false,
      });
    }
  };

  handleUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      this.setState({ isUploading: true });
      reader.onload = (event) => {
        console.log(this);
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          let rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          let URL = BaseUrl + "admin/product/import";

          const payload = {
            data: rows,
          };

          var loginDetails = {};
          if (sessionStorage.getItem("authData")) {
            loginDetails = JSON.parse(sessionStorage.getItem("authData"));
          }

          http
            .post(URL, payload)
            .then((response) => {
              this.getProducts(this.state.priceCode);
              this.setState({
                isUploading: false,
                uploadMessage: "Products Imported successfully!",
                showPopup: true,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  handlePrdpriceUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      this.setState({ isUploading: true });
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          let rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          let URL = BaseUrl + "admin/product/importprice";

          const payload = {
            data: rows,
          };

          var loginDetails = {};
          if (sessionStorage.getItem("authData")) {
            loginDetails = JSON.parse(sessionStorage.getItem("authData"));
          }

          http
            .post(URL, payload)
            .then((response) => {
              this.getProducts(this.state.priceCode);
              this.setState({
                isUploading: false,
                uploadMessage: "Price Band Imported successfully!",
                showPopup: true,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  closePopup = () => {
    this.setState({ showPopup: false, uploadMessage: "" });
  };

  importButtonClick = (value) => {
    this.setState({ popupImportView: true, importValue: value });
  };

  importClosepopup = (event, shouldUpload, file, importValue) => {
    if (shouldUpload && file) {
     
      this.uploadFile(file, importValue);
    }
    this.setState({
      popupImportView: false,
      importValue: "",
      selectedFile: null,
    });
  };

  uploadFile = (file, importValue) => {
    // Implement the actual upload logic here
    // For example, using FileReader and axios as discussed earlier
    if (importValue === "Product") {
      this.handleUpload(file);
    } else if (importValue === "Price Band") {
      this.handlePrdpriceUpload(file);
    }
  };

  render() {
    if (sessionStorage.getItem("authData")) {
      var loginemail = JSON.parse(sessionStorage.getItem("authData")).adminEmail;
    }
    const columns = [
      {
        name: "Product Image",
        selector: (row) => (
          <div onClick={this.handleImage.bind(this, row)}>
            {" "}
            <img
              width="64"
              src={
                row.productImage !== "" && row.productImage !== null
                  ? row.productImage
                  : NoImage
              }
              onContextMenu={this.handleContextMenu}
              style={{ cursor: "not-allowed" }}
            />{" "}
          </div>
        ),
        sortable: false,
      },
      {
        name: "Product Name",
        selector: (row) => row.productDescription,
        sortable: false,
      },
      {
        name: "Product Code",
        selector: (row) => row.productNumber,
        sortable: false,
      },

      {
        name: "Unit of Sale",
        selector: (row) => row.packageSize,
        sortable: false,
      },
      {
        name: "Category",
        selector: (row) =>
          row.categoryId === "1"
            ? "PIZZA"
            : row.categoryId === "10" || row.categoryId === "13"
            ? "PASTA, RICE & NOODLE SAUCES"
            : "BURRITO",
        sortable: true,
      },
      {
        name: "Actions",
        selector: (row, index) => (
          <div style={{ display: "flex" }} className="action-btns">
            <a
              className="button title-tooltip title-up"
              data-title="View Details"
              style={{ color: "white" }}
              onClick={() => this.viewProductDetails(row)}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </a>
            {(loginemail === 'karthik@k2bsolutions.in' || loginemail === 'k2bsolutions@gmail.com') && (<a
              className="button title-tooltip title-up"
              data-title="Edit Product Details"
              style={{ color: "white" }}
              onClick={() => this.editProduct(row)}
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </a>)}
            <a
              className="button title-tooltip title-up"
              data-title="View Price band Details"
              style={{ color: "white" }}
              onClick={() => this.viewPricebandDetails(row)}
            >
              <i className="fa fa-gbp" aria-hidden="true"></i>
            </a>
            <a
              className="title-tooltip title-up"
              data-title="Product Activate/Deactivate"
            >
              <button
                onClick={() => this.toggleProductStatus(row.productNumber)}
                className={`button ${
                  row.PDP_Status === "activate" ? "activate" : "deactivate"
                }`}
              >
                <i
                  className={`fa ${
                    row.PDP_Status === "activate" ? "fa-unlock" : "fa-lock"
                  }`}
                  aria-hidden="true"
                ></i>
              </button>
            </a>
            {
              <a
                className="button title-tooltip title-up"
                data-title="Product Status Log"
                onClick={() =>
                  this.ProductStatuslog(
                    row.productNumber,
                    row.productDescription
                  )
                }
              >
                <i className="fa fa-history"></i>
              </a>
            }
          </div>
        ),
        sortable: false,
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: "25px",
        },
      },
      headCells: {
        style: {
          padding: "8px", // override the cell padding for head cells
        },
      },
      cells: {
        style: {
          padding: "8px", // override the cell padding for data cells
        },
      },
    };

    const paginationComponentOptions = {
      rowsPerPageText: "",
      rangeSeparatorText: "",
      selectAllRowsItem: true,
      selectAllRowsItemText: "",
      noRowsPerPage: "",
    };

    
    return (
      <>
        {this.state.alertPopup == true && (
          <CommonAlert title="Alert" message={this.state.alertMessage} />
        )}
        <section className="dashboard-block">
          <Sidebar />

          <div className="dash-left">
            <Header />

            <section className="cart-block">
              <div className="container">
                {/* <h1 className="checkout-title">Products</h1> */}
                <div className="flex-title">
                  <h1 className="checkout-title">Products</h1>
                  <div className="prod-import-btn">
                  {(loginemail === 'karthik@k2bsolutions.in' || loginemail === 'k2bsolutions@gmail.com') && (
                    <>
                    <button onClick={() => this.importButtonClick("Product")}>
                      Product Import
                    </button>
                    <button
                      onClick={() => this.importButtonClick("Price Band")}
                    >
                      Price Band Import
                    </button>
                    </>
                  )}
                  </div>
                </div>
                <div className="checkout-table">
                  <div className="table-filter search-orders">
                    <div className="table-search-reset">
                      <div className="products-search-left">
                        <div className="table-search">
                          <input
                            type="text"
                            value={this.state.searchTextVal}
                            onChange={(e) => this.handleChange(e.target.value)}
                            className="form-control"
                            placeholder="Search by Product Code, Product Name"
                          />
                        </div>
                        <button
                          className="submitbtn button search-prd-btn"
                          onClick={(e) =>
                            this.filterData('search',this.state.searchTextVal)
                          }
                        >
                          <i className="fa fa-search"></i>
                        </button>

                        <button
                          className="resetbtn"
                          type="reset"
                          onClick={this.handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                   
                    <div
                      className={
                        this.state.filterValueList.length > 0
                          ? "select-drop"
                          : "select-drop no-filter-list"
                      }
                      ref={this.dropdownRef}
                    >
                       <div className="orders-dropdown prod-select-drop">
                        <select
                          className="handle-srch"
                          value={this.state.selectedStatus}  // Bind the value to state
                          onChange={(e) => this.handlefilterChange(e.target.value)}
                        >
                          <option value="" disabled>
                            Status
                          </option>
                          <option value=" ">All</option>
                          <option value="activate">Active</option>
                          <option value="deactivate">Inactive</option>
                         
                        </select>
                      </div>
                      <a
                        className={
                          this.state.isElementVisible == true
                            ? "filter-btn button active"
                            : "filter-btn button"
                        }
                        onClick={this.openFilterOption}
                      >
                        More Filters{" "}
                        <span className="filter-icon">
                          <i className="fa fa-sliders" aria-hidden="true"></i>
                        </span>
                      </a>

                     
                    </div>
                  </div>

                  {this.state.filterSelectedValue.length > 0 &&
                    this.state.filterapply && (
                      <div className="filter-field-wrap">
                        <h3>Filtered by:</h3>
                        <div className="filter-show-bx">
                          {this.state.filterSelectedValue.map((item) => (
                            <span
                              className="filters"
                              key={item}
                              onClick={(e) => this.removeFilter(e, item)}
                            >
                              <a className="filter-x"></a>
                              {capitalizeFirstLetter(item)}:
                              <span className="filter-count">
                                {this.state.filterCounts[item]}
                              </span>
                            </span>
                          ))}
                          <span
                            className="filters clr-all"
                            onClick={this.clearFilterSearch.bind(this)}
                          >
                            Clear All
                          </span>
                        </div>
                      </div>
                    )}

                  <div className="table_overflow products-table">
                    <DataTable
                      columns={columns}
                      data={this.state.products}
                      customStyles={customStyles}
                      noDataComponent={
                        <div className="no-records-found">
                          Sorry, no products were matching your keyword, try a
                          new search.
                        </div>
                      }
                      pagination
                      paginationPerPage={25}
                      progressPending={this.state.loading}
                      paginationRowsPerPageOptions={[25, 50, 75, 100]}
                      paginationComponentOptions={paginationComponentOptions}
                    />
                    {/* } */}
                  </div>
                  {/* {this.state.loading && 
                                    <Loader />
                                    } */}
                </div>
              </div>
            </section>
            <Footer />
          </div>
          {this.state.showReasonPopup && (
            <div className="custom-alert-pop paylater-popup">
              <div className="custom-alert-container">
                <div className="custom-alert-wrap">
                  <div className="custom-alert-header">
                    <h4>{this.state.confirmationAction.action}</h4>
                  </div>
                  <div className="custom-alert-body">
                    <div className="input-field">
                      <span className="labeltxt">Post your comments</span>
                      <textarea
                        name="reason"
                        onChange={this.handleReasonChange}
                      ></textarea>
                      {this.state.reasonError && (
                        <div className="err-msg">Please update some reason</div>
                      )}
                    </div>

                    <div className="alert-buttons">
                      <button
                        onClick={this.showConfirmationDialog}
                        className="popup-alert-btn button"
                      >
                        Submit
                      </button>
                      <button
                        onClick={this.hideConfirmationDialog}
                        className="popup-alert-btn button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.showDialog && (
            <div className="custom-alert-pop paylater-popup">
              <div className="custom-alert-container">
                <div className="custom-alert-wrap">
                  <div className="custom-alert-header">
                    <h4>Alert</h4>
                  </div>
                  <div className="custom-alert-body">
                    <p>
                      Are you sure you want to{" "}
                      {this.state.confirmationAction.action} the product?
                    </p>

                    <div className="alert-buttons">
                      <button
                        onClick={this.confirmActionHandler}
                        className="popup-alert-btn button"
                      >
                        Yes
                      </button>
                      <button
                        onClick={this.hideConfirmationDialog}
                        className="popup-alert-btn button"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.showPopup && (
            <div className="custom-alert-pop paylater-popup">
              <div className="custom-alert-container">
                <div className="custom-alert-wrap">
                  <div className="custom-alert-header">
                    <h4>Alert</h4>
                  </div>
                  <div className="custom-alert-body">
                    <p>{this.state.uploadMessage}</p>

                    <div className="alert-buttons">
                      <button
                        onClick={this.closePopup}
                        className="popup-alert-btn button"
                      >
                        ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.popupView}
          {this.state.popuplogView}
          {this.state.editProdDet && (
            <EditProductDetails
              editProddet={this.state.ProductDet}
              editloading={this.state.loading}
              popupclose={this.editclosepopup}
              editProdDetails={this.editPrdDetails}
            />
          )}

          {this.state.pricebandView}
          {this.state.viewImage}
          {this.state.popupImportView && (
            // <div className="popup-overlay">
            <Importpopup
              closePopup={this.importClosepopup}
              impValue={this.state.importValue}
            />
            // </div>
          )}
          {this.state.isUploading && (
            <div className="loading-overlay">
              <div className="loading-message">Importing...</div>
            </div>
          )}
          { this.state.moreFilter && 
          (
            <div class="product-import-pop more-filter-pop">
        <div class="product-import-pop-overlay"></div>
        <div class="product-import-wrap">
            <span class="close-import-pop" onClick={this.closeFilterOption}></span>
            
            <div class="product-import-body">
                <div class="product-import-header">
                    <h3>More Filters</h3>
                </div>
                <div class="product-import-content">
                    <div class="filter-accordion-grp">
                        <h5>Suitable For</h5>
                        <div class="filter-options">
                        {this.state.filterValueList.length == 0 && (
                          <div className="no-filter">
                            <p>Sorry, no filters are set for this category.</p>
                          </div>
                        )}
                        {this.state.filterValueList.length > 0 &&
                            this.state.filterValueList.map((item, index) => {
                              return (
                                <a
                                  className={
                                    !this.state.tempFilterSelectedValue.includes(
                                      item.toLowerCase()
                                    )
                                      ? "filter-val"
                                      : "filter-val active"
                                  }
                                  onClick={() => this.filterData('suitablefor',item)}
                                  key={index}
                                >
                                  {item}{" "}
                                  <span
                                    className="remove-filter"
                                    onClick={(e) => this.removeFilterpop(e, item)}
                                  ></span>
                                </a>
                              );
                            })}
                           
                        </div>
                    </div>
                    
                    <div class="filter-accordion-grp">
                        <h5>Product Group</h5>
                        <div class="filter-options">
                        {this.state.groupFilterList.length > 0 &&
                            this.state.groupFilterList.map((item, index) => {
                              return (
                                <a
                                  className={
                                    !this.state.groupFilterSelectedValue.includes(
                                      item.toLowerCase()
                                    )
                                      ? "filter-val"
                                      : "filter-val active"
                                  }
                                  onClick={() => this.filterData('groupfilter',item)}
                                  key={index}
                                >
                                  {item}{" "}
                                  <span
                                    className="remove-filter"
                                    onClick={(e) => this.removeFilterpop(e, item)}
                                  ></span>
                                </a>
                              );
                            })}
                               
                        </div>
                    </div>
                    <div class="filter-accordion-grp">
                        <h5>Tax Code</h5>
                        <div class="filter-options">
                        {this.state.taxFilterList.length > 0 &&
                            this.state.taxFilterList.map((item, index) => {
                              return (
                                <a
                                  className={
                                    !this.state.taxFilterSelectedValue.includes(
                                      item.toLowerCase()
                                    )
                                      ? "filter-val"
                                      : "filter-val active"
                                  }
                                  onClick={() => this.filterData('taxfilter',item)}
                                  key={index}
                                >
                                  {item}{" "}
                                  <span
                                    className="remove-filter"
                                    onClick={(e) => this.removeFilterpop(e, item)}
                                  ></span>
                                </a>
                              );
                            })}
                           
                        </div>
                    </div>
                </div>
                <div class="product-import-bottom">
                    <div class="filter-btns">
                        <button type="button" class="button clear-btn" value="Clear Filters" onClick={this.clearFilterSearch.bind(this)} >Clear</button>
                        <button type="button" class="button apply-btn" value="Apply Filters" onClick={this.applyFilter.bind(this)}>Apply </button>
                    </div>
                </div>
            </div>
        </div>
      </div>
           
          
          
             
           )

          }
        </section>

        {/* Loader & Popup START */}
        <div className="common">
          {/* <Alert extractId="success" customContent="Cart Added Success" /> */}
        </div>
        {/* Loader & Poupp END */}
      </>
    );
  }
}

export default Products;

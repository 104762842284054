import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
export const TopSellProducts = ({ }) => {

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const columns = [
        {
            name: "Product Code",
            selector: row => {
                if (row.ProductNumber && typeof row.ProductNumber === "string") {
                    return row.ProductNumber.includes("_")
                        ? row.ProductNumber.substring(0, row.ProductNumber.indexOf("_"))
                        : row.ProductNumber;
                }
                return ""; // Return a default value if ProductNumber is undefined or not a string
            },
            sortable: false,
        },
        {
            name: "Product Name",
            selector: row => (
                <>
                    {row.Description.replaceAll("amp;", "&")
                        .replaceAll("apos;'", "'' ")
                        .replaceAll("quot;'", "'' ")}

                    <a
                        className={row.UnitPrice === 0  && row.QuantityOrdered > 0  ? "free title-tooltip title-top" : "title-tooltip title-top"}
                        data-title={row.categoryId === "1"
                            ? "PIZZA"
                            : row.categoryId === "10" || row.categoryId === "13"
                                ? "PASTA, RICE & NOODLE SAUCES"
                                : "BURRITO"}>
                        <i className="fa fa-th-list" aria-hidden="true"></i>
                    </a>
                </>
            ),
            sortable: false,
        },

        {
            name: "Quantity Sold",
            selector: row => row.sum,
            sortable: false,
        }

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '25px',
            },
        },
        headCells: {
            style: {
                padding: '8px', // override the cell padding for head cells

            },
        },
        cells: {
            style: {
                padding: '8px', // override the cell padding for data cells

            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        rangeSeparatorText: "",
        selectAllRowsItem: true,
        selectAllRowsItemText: "",
        noRowsPerPage: "",
    };

    const [filter, setFilter] = useState(0);
    const [catFilter, setCatFilter] = useState(0);
    const [filtertext, setFilterText] = useState('');
    const [topSellingProducts, setTopSellingProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect(() => {
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let URL1 = BaseUrl + "admin/topsellers";
        let payload = {};
        if (filter === 'custom') {
            payload = {
                filter: filter, datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                category: catFilter,
                dateto: format(new Date(toDate), "yyyy-MM-dd")
            }
            setShowDatePicker(false);
        }
        else {
            payload = {
                filter: parseInt(filter), datefrom: '',
                dateto: '',
                category: catFilter,
            }
        }

        http.post(URL1, payload).then((response) => {
            setIsLoading(false);
            setTopSellingProducts(response.data.data.filter((item) => item.sum > 0));
        }).catch((err) => {
            console.log(err);
        })


    }, [filter, fromDate, toDate, catFilter]);

    // Calculate dynamic pagination options
    const calculatePaginationOptions = (totalRecords, interval) => {
        const options = [];
        for (let i = interval; i <= totalRecords; i += interval) {
            options.push(i);
        }
        return options;
    };


    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    const totalRecords = topSellingProducts.length; // Get the total records count
    const paginationOptions = calculatePaginationOptions(totalRecords, 10); // Generate options with interval of 10

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    }

    const changeCatFilterHandler = (e) => {
        setCatFilter(e.target.value);
        setShowDatePicker(false);
    }
    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === 'custom') {
            setShowDatePicker(true);

        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);

        }
    };
    let loadingbuffer = isLoading ? 'loading-buffer' : '';
    return (
        <div className={`chart-container chart-top-selling ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <div className="chart-Hdr-txt">
                    <h2>Top Selling Products </h2>

                </div>

                <div className="chart-fltr">
                    {<select
                        value={catFilter}
                        onChange={(e) => changeCatFilterHandler(e)}
                    ><option value={"all"}>All</option>
                        <option value={"pizza"}>PIZZA</option>
                        <option value={"pasta-rice-noodle-sauces"}>PASTA, RICE & NOODLE SAUCES</option>
                        <option value={"burrito"}>BURRITO</option>
                    </select>}
                </div>
                <div className="chart-fltr" onMouseEnter={() => {
                    if (filter === "custom" && !showDatePicker) {
                        setShowDatePicker(true);
                    }
                }}
                    onMouseLeave={() => {
                        if (filter === "custom" && showDatePicker) {
                            setShowDatePicker(false);

                        }
                    }}>
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}
                    ><option value={0}>All</option>
                        <option value={1}>Past 1 Day</option>
                        <option value={30}>Past 30 Days</option>
                        <option value={90}>Past 90 Days</option>
                        <option value={180}>Past 180 Days</option>
                        <option value={365}>Past 1 Year</option>
                        <option value={'custom'}>Custom Date</option>
                    </select>}
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => DateChange(date, "from")}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                            <label>To:</label>
                            <DatePicker
                                selected={toDate}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => DateChange(date, "to")}
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                        </div>
                    )}
                </div>
                <div className="fltr-sub-val">
                    {topSellingProducts.length > 0 && (<span className='fltr-val'>Total Qty Sold: <strong>
                        {topSellingProducts.reduce((accumulator, item) => {
                            return item.sum > 0 ? accumulator + item.sum : accumulator;
                        }, 0)}
                    </strong> ({(filtertext != '' && filtertext != 'Custom Date') ? filtertext : (filtertext === 'Custom Date' && fromDate && toDate) ? formatDate(fromDate) + ' - ' + formatDate(toDate) : 'All'})</span>)}
                </div>
            </div>
            <DataTable
                columns={columns}
                data={topSellingProducts}
                customStyles={customStyles}
                noDataComponent={<div className='no-records-found'>There are no records to display</div>}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 40, 60, 80, 100]}
                //paginationRowsPerPageOptions={paginationOptions} // Dynamic intervals
                paginationComponentOptions={paginationComponentOptions}
            // paginationComponentOptions={{
            //     rowsPerPageText: '', // Hides "Rows per page"
            // }}

            />
        </div>
    );
};
import React, { Component, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import { http } from '../helper'; 
import { BaseUrl } from '../common/config';
import CommonAlert from '../common/Commonalert';
import Sidebar from '../common/sidebar';
import ShowAlert from '../common/Showalert';

const MasterEntryEdit=()=> {
  
    const [MasterValue, setMasterValue] = useState([]);
    const [MasterCategory, setMasterCategory] = useState([]);
    const [premastervalue, setPreMastervalue] = useState([]);   
    const [errors, setErrors] = useState({});    
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);
    const [alerterrorPopup,setAlertErrorPopup] = useState(false);
    const [alerterrMessage,setAlerterrMessage] = useState([]);
    const [masterlist, setMasterlist] = useState([]);
    const params = useParams();
    if(!sessionStorage.getItem("isLoggedIn"))
        window.location.href = '/';

    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_masterentry'))
        {
          window.location.href = '/unauthorized';
        }

    useEffect(()=>
    {
   
            var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         } 
         setMasterCategory((params.name === 'productgroup')?'Product Group':(params.name === 'suitablefor')?'Suitable For':(params.name === 'taxcode')?'Tax Code':'Sectors');
        let URL = BaseUrl + `admin/product/getmasterentries/${params.id}`;
        http.get(URL).then((response) => {
            let responseData = response.data.data;
            
            setMasterValue(responseData.MasterValue);
            setPreMastervalue(responseData.MasterValue);
            //setHolidayTitle(responseData.title);
                
        }).catch((err) => {
            console.log(err);
            
        })  

        let URL1 = BaseUrl + `admin/product/masterentries/${params.name}`;
        http.get(URL1).then((response) => {
            let responseData1 = response.data.data;
                     
            setMasterlist(responseData1);
        }).catch((err) => {
            console.log(err);
        })


    },[])
    const routeChange = () =>{ 
        window.location.href=`/master-entry/${params.name}`;
    }
    const btnsubmit = (e) =>
    {
        e.preventDefault();
        
        const isValid = formvalidation();
 
      
          
       if(isValid)
       {
        let Mastervalue = MasterValue.trim();
        let payload = {
            id : params.id,
            MasterValue   : Mastervalue,
         }
         if(premastervalue != Mastervalue)
         {
            var isFound = masterlist.some(element => {
                if (element.MasterValue.toLowerCase() === Mastervalue.toLowerCase()) {
                  return true;
                }
            
                return false;
              });
         }
         else{
            var isFound = false;

         }
         
        if(isFound)
        {
            setAlertErrorPopup(true);
            setAlerterrMessage('This data already exists! Please enter an unique value.'); 

        }
        else
        {
            var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
               loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            } 
   
        let URL = BaseUrl + "admin/product/editmaster";
        http.put(URL, payload).then(async (response) => {
                let responseData = response.data.data;
                if (response.data.success === true) {
                    setAlertPopup(true);
                    setAlertMessage(`Value updated successfully`); 
                }
            }).catch((err) => {
            console.log(err);
            
            })
        }
       }
        
    }

    const formvalidation = () =>
    {
       // const errmessage = {};
        const errmsg = {};
        let isValid = true;
        if (!MasterValue.trim()) {
            errmsg.MasterValue = "Master Value is required";
            isValid = false;
        }

        setErrors(errmsg);
        return isValid;
        
    }

  const handleOnChange = (e) =>{
    const re = /^[a-zA-Z0-9-' &.]*$/;

    if(e.target.name ==="MasterValue")
    {
        if (e.target.value === '' || re.test(e.target.value)) {
            setMasterValue(e.target.value);
        }

    }
    const { name, value } = e.target;

    setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
    }));
   
   
  }
  return (
    
    <section className="dashboard-block">
        { alerterrorPopup &&  ( <ShowAlert title='Alert' message={alerterrMessage}/> )}
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    <div className="dash-right">
            <Sidebar />
    </div>
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container account-details">

         <div className="right-table-outer-wrap">
            <h1 className="checkout-title">Edit {MasterCategory}</h1>
            <div className='form-field freeproduct-fieldset ingredients-fieldset form-container-xs'>
                        <form className='sectors-detail-form'>
                        <div className='form-field col-1'>
                                <label for='name'>{MasterCategory}</label>
                                <input type='text' name='MasterValue' value={MasterValue} onChange={handleOnChange} />
                                {errors.MasterValue && <div style={{color:"red"}}>{errors.MasterValue}</div>}

                            </div>
                           
                            <div className='btnfield'>
                            <button type="submit" name="btnsubmit" onClick={btnsubmit}>Submit</button>
                            &nbsp;&nbsp;&nbsp;
                            <button type="button" onClick={routeChange}>Cancel</button>
                            </div>
                           
                            
                        </form>
                    </div>
            </div>
         </div>
         <Footer/> 
        </section>
       
            </div>
          
        </section>
  )
}
export default MasterEntryEdit;
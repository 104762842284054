import React, { Component } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import { http } from '../helper';
import DataTable from 'react-data-table-component';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import Popup from '../common/popup';
import moment from "moment";



class Freecode extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            searchTextVal:"",
            freecodelist: [],
            templist :[],
            loading: true,
            popupView: "",
            visibleAlert:false,
            freecodevalue : '',
        };

        if(!sessionStorage.getItem("isLoggedIn")){
            window.location.href = '/';
        }
        
        if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
            {
              window.location.href = '/unauthorized';
            }
     }

    getFreecodelist() {

      var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         }


        let URL = BaseUrl + "admin/freecodelist";
        http.get(URL).then((response) => {
            //const sortedData = response.data.data.sort((a, b) => new Date(b.holidayDate) - new Date(a.holidayDate));
            this.setState({ freecodelist: response.data.data, loading: false, templist:response.data.data});
        }).catch((err) => {
            console.log(err);
        })
    }

    viewFreecodeDetails(code) {
        let popupResult = <Popup
            extClass="order-pop title-left pop-container-sm"
            headMoreTxt={
                <>
                    <div className="order-top">
                        <div className="head">
                            <h4>Code Details</h4>
                        </div>
                    </div>
                </>
            }
            bodyMoreTxt={
                <>
                    <div className="order-top">
                        
                        <div className="bottom vendor-details holidaydetails">
                            <p><span className="labeltxt">Code</span>: {code.Code}</p>
                            <p><span className="labeltxt">Status</span>: {code.FreeCodeStatus}</p>
                            
                            
                        </div>
                    </div>
                </>
            }
            closebtn={<><span onClick={() => this.setState({ popupView: "" })} className='close'></span></>}

           
        />;

        this.setState({ popupView: popupResult });

    }

    

    deleteFreecode = (value) =>
    {
        this.setState({visibleAlert:true,freecodevalue:value});
        
    }

    deleteFreecodeValue = ()=>
    {

         var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         }

        let URL = BaseUrl + `admin/freecode/${this.state.freecodevalue._id}`;  
        http.delete(URL).then(async (response) => {
         
            if(response.data.success === true){
                
                window.location.reload();
            }

        
        }).catch((err) => {
            console.log(err);
            
        })  
    }
    alertPopupclose = () =>
    {
        this.setState({visibleAlert:false,freecodevalue:''});
    }
    componentDidMount() {
        this.getFreecodelist();
    }

    addfreecode = () =>
    {
        window.location.href = '/freecode/add/';
    }

    editFreecodeDetails=(freecode)=>{
        window.location.href = `/freecode/edit/${freecode._id}`;
    }

    handleReset=()=>{
        this.setState({searchTextVal:'',freecodelist:this.state.templist});
     };

    handleChange = async(value) => {
        this.setState({searchTextVal:value});
        this.filterData(value);
      };
           

      filterData = async(value) => {

        let accoun = this.state.templist;
        
        const lowercasedValue = value.toLowerCase().trim();
        let result = [];
        if (lowercasedValue.length>0){
            accoun.map((item)=>{
            if(item.Code.toString().toLowerCase().includes(lowercasedValue))
            {
              result.push(item);
            }
          })
          this.setState({freecodelist:result});
        }else {
        this.setState({freecodelist:accoun});
      }
      };
      
      



      toggleStatus = (row) => {
        const updatedStatus = row.FreeCodeStatus === 'A' ? 'I' : 'A'; // Toggle between Active (A) and Inactive (I)
        let URL = BaseUrl +"admin/freecodestatuschange";
        const payload = {
             Code:row._id,
             Freecodestatus : updatedStatus,
         };
       
        http.post(URL,payload).then((response)=>{

            let responseData = response.data.data;
            if (response.data.success === true) {
                this.setState(prevState => ({
                    freecodelist: prevState.freecodelist.map(item =>
                        item.Code === row.Code ? { ...item, FreeCodeStatus: updatedStatus } : item
                    )
                }));
                this.setState(prevState => ({
                    templist: prevState.templist.map(item =>
                        item.Code === row.Code ? { ...item, FreeCodeStatus: updatedStatus } : item
                    )
                }));
                setAlertPopup(true);
                setAlertMessage('Status updated successfully'); 
            }

        }).catch((error)=>{
            console.log(error)
    
        });

          
    };


    render() {

        const columns = [
            {
                name: "Code",
                selector: row => row.Code,
                sortable: true,
            },
            {
                name: "Status",
                selector: row => (row.FreeCodeStatus === 'A')?'Active':'Inactive',
                sortable: false,
            },            
            {
                name: "Actions",
                selector: row => <div style={{display:"flex"}}>
                <a 
                    className='button title-tooltip title-up' 
                    data-title={row.FreeCodeStatus === 'A' ? 'Inactive' : 'Active'} 
                    style={{ color: "white", cursor: 'pointer' }} 
                    onClick={() => this.toggleStatus(row)}
                >
                    <i 
                        className={row.FreeCodeStatus === 'A' ? "fa fa-unlock" : "fa fa-lock"} 
                        aria-hidden="true"></i>
                </a>
                    <a className='button title-tooltip title-up' data-title='Delete' style={{color: "white"}} onClick={this.deleteFreecode.bind(this, row)}><i className="fa fa-trash" aria-hidden="true"></i></a>
                                     
                    </div>,
                sortable: false,
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '25px',
                },
            },
            headCells: {
                style: {
                    padding: '8px', // override the cell padding for head cells
                    
                },
            },
            cells: {
                style: {
                    padding: '8px', // override the cell padding for data cells
                    
                },
            },
        };

        const paginationComponentOptions = {
            rowsPerPageText: '',
            rangeSeparatorText: '',
            selectAllRowsItem: true,
            selectAllRowsItemText: '',
            noRowsPerPage:'',
        };

        return (
            <>
                <div className="dashboard-block">

                    
                        <Sidebar />
                    
                    <div className="dash-left">
                        <Header />

                        <section className="cart-block">
                        <div className="container">
                            <h1 className="checkout-title">Free Sample Code</h1>  

                            <div className="right-table-outer-wrap">
                            <div className="table-filter search-orders">
                              <div className='table-search-reset'>
                                <div className="table-search">
                                    <input type="text" value={this.state.searchTextVal} onChange={e => this.handleChange(e.target.value)} className="form-control" placeholder="Search by Code" />
                                    
                                </div>
                                <button className='submitbtn button search-holi-btn' onClick={e => this.filterData(this.state.searchTextVal)}><i className="fa fa-search"></i></button>
                               
                                <button className='resetbtn' type='reset' onClick={this.handleReset}>Reset</button>
                              </div>
                             
                              <div className='add-holidaybtn'>
                                <button className='addbtn' onClick={this.addfreecode}>Add</button>
                              </div>
                            </div>
                            
                            <div className="table_overflow">
                           
                                    <DataTable
                                        columns={columns}
                                        noDataComponent={<div className='no-records-found'>Sorry, no free code were matching your keyword, try a new search.</div>}
                                        data={this.state.freecodelist}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={25}
                                        progressPending={this.state.loading}
                                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                        paginationComponentOptions={paginationComponentOptions }
                                    />
                                    
                                    </div>
                                   
                    

                            </div>
                        </div>
                        </section>
                        <Footer />
                        
                    </div>
                </div>
                {this.state.popupView}
                {this.state.visibleAlert === true && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to delete?</p>

                                <div className='alert-buttons'>

                                    <a onClick={this.deleteFreecodeValue.bind(this)} className="popup-alert-btn button" >OK</a>
                                    <a onClick={this.alertPopupclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </>
        );
    }
}

export default Freecode;
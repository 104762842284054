import React, { useEffect, useState, memo } from "react";
import { Pie } from "react-chartjs-2";
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

export const PieChart = memo(({ }) => {
    var currentTime = new Date();

    const [filter, setFilter] = useState(0);
    const [filtertext, setFilterText] = useState('');
    const [paylaterOrders, setPaylaterOrders] = useState(0);
    const [onlinePaymentOrders, setOnlinePaymentOrders] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const noDataPlugin = {
        id: "noDataPlugin",
        beforeDraw(chart) {
            const { datasets } = chart.data;
            const hasData = datasets.some(dataset => dataset.data.some(value => value > 0));

            if (!hasData) {

                const { ctx, width, height } = chart;
                const text = "Sorry, no data found for your filtered search.";
                const maxWidth = width * 0.8; // Set a max width (80% of the chart width)
                const lineHeight = 20; // Line height in pixels
                const x = width / 2;
                const y = height / 2;
                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "14px Arial";
                ctx.fillStyle = "#ed5264"; // Color for the text
                // Function to break the text into multiple lines
                const wrapText = (ctx, text, maxWidth) => {
                    const words = text.split(" ");
                    let line = "";
                    const lines = [];

                    words.forEach(word => {
                        const testLine = line + word + " ";
                        const { width: testWidth } = ctx.measureText(testLine);

                        if (testWidth > maxWidth) {
                            lines.push(line.trim());
                            line = word + " ";
                        } else {
                            line = testLine;
                        }
                    });

                    if (line) lines.push(line.trim());
                    return lines;
                };

                // Get the wrapped lines
                const lines = wrapText(ctx, text, maxWidth);

                // Calculate the starting Y position for the text
                const startY = y - (lines.length - 1) * (lineHeight / 2);

                // Draw each line
                lines.forEach((line, index) => {
                    ctx.fillText(line, x, startY + index * lineHeight);
                });

                ctx.restore();
            }
        }
    };


    const [chartData, setChartData] = useState({
        labels: ['Online Payment Orders', 'Pay Later Orders'],
        datasets: [
            {
                label: "Orders",
                data: [onlinePaymentOrders, paylaterOrders],
                backgroundColor: [
                    "rgba(252,194,84,1)",
                    "#f4ecec",

                ],
                borderColor: "black",
                borderWidth: 2
            }
        ]
    });

    useEffect(() => {

        setChartData({
            labels: ['Online Payment', 'Pay Later'],
            datasets: [
                {
                    label: "Orders",
                    data: [onlinePaymentOrders, paylaterOrders],
                    type: 'doughnut',
                    backgroundColor: [
                        "#a1e8eb",
                        "rgba(195,183,248,1)",
                    ],
                    borderColor: "#e6e6e6",
                    borderWidth: 1
                }
            ]
        });
    }, [onlinePaymentOrders, paylaterOrders])

    useEffect(() => {
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let payload = {};
        if (filter === 'custom') {
            payload = {
                filter: filter, datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                dateto: format(new Date(toDate), "yyyy-MM-dd")
            }
            setShowDatePicker(false);
        }
        else {
            payload = {
                filter: parseInt(filter), datefrom: '',
                dateto: ''
            }
        }
        let URL = BaseUrl + "admin/paymentorders";
        http.post(URL, payload).then((response) => {
            setIsLoading(false);
            setOnlinePaymentOrders(response?.data?.data?.onlinePaymentOrders);
            setPaylaterOrders(response?.data?.data?.payLaterOrders);

        }).catch((err) => {
            console.log(err);
        })
    }, [filter, fromDate, toDate]);
    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === 'custom') {
            setShowDatePicker(true);

        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);

        }
    };

    const legendMargin = {
        id: "legendMargin",
        beforeInit: function (chart) {
            const fitValue = chart.legend.fit;
            chart.legend.fit = function fit() {
                fitValue.bind(chart.legend)();
                return (this.height += -10);
            };
        }
    };

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    }

    let loadingbuffer = isLoading ? 'loading-buffer' : '';

    return (

        <div className={`chart-container chart-order-count ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <div className="chart-Hdr-txt">
                    <h2>Orders Count</h2>

                </div>
                <div className="chart-fltr" onMouseEnter={() => {
                    if (filter === "custom" && !showDatePicker) {
                        setShowDatePicker(true);
                    }
                }}
                    onMouseLeave={() => {
                        if (filter === "custom" && showDatePicker) {
                            setShowDatePicker(false);

                        }
                    }}>
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}

                    ><option value={0}>All</option>
                        <option value={30}>Past 30 Days</option>
                        <option value={90}>Past 90 Days</option>
                        <option value={180}>Past 180 Days</option>
                        <option value={365}>Past 1 Year</option>
                        <option value={'custom'}>Custom Date</option>
                    </select>}
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => DateChange(date, "from")}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select From Date"
                            />
                            <label>To:</label>
                            <DatePicker
                                selected={toDate}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => DateChange(date, "to")}
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select To Date"
                            />
                        </div>
                    )}
                </div>
                <div className="fltr-sub-val">
                    {(onlinePaymentOrders > 0 || paylaterOrders > 0) && (<span className='fltr-val'>Total Records: <strong>{(onlinePaymentOrders + paylaterOrders)}</strong> ({(filtertext != '' && filtertext != 'Custom Date') ? filtertext : (filtertext === 'Custom Date' && fromDate && toDate) ? formatDate(fromDate) + ' - ' + formatDate(toDate) : 'All'})</span>)}
                </div>
            </div>
            {chartData && <Pie
                data={chartData}
                plugins={[legendMargin, noDataPlugin]}
                options={{
                    plugins: {
                        title: {
                            display: false,

                        },
                        legend: {
                            display: true,
                            align: 'left',
                            position: 'bottom',
                            labels: {
                                color: "#969696",
                                font: {
                                    size: 12
                                },
                                padding: 20,
                            },
                        },
                        tooltip: {
                            titleColor: 'rgba(220, 222, 226, 1)',
                            bodyColor: 'rgba(220, 222, 226, 1)'
                        },
                        datalabels: {
                            display: false,
                            font: {
                                weight: 'bold'
                            },
                        }
                    }
                }}
            />}
        </div>
    );
});
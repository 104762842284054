import React, { useState, useEffect} from 'react';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import CommonAlert from '../common/Commonalert';

const AddFreeproducts = () =>{
    
    const [loading,setLoading] = useState(true);
    const [freeloading,setfreeLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [freeproducts, setfreeProducts] = useState([]);
    
    const [expanded , setExpanded] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedFreeCategories, setSelectedFreeCategories] = useState([]);
    const [checkedval, setCheckedval] = useState([]);
    const [checkedfreeval , setCheckedfreeval] = useState([]);
    const [checkedState, setCheckedState] = useState([]);
    const [checkedfreeState, setCheckedfreeState] = useState([]);
    const [inputlimitField, setInputlimitField] = useState([]);
    const [inputFieldtitle,setInputFieldtitle] = useState([]);
    const [inputfreelimitField, setInputfreelimitField] = useState([]);
    const [errmessage, setErrMessage] = useState({});
    
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);

    if(!sessionStorage.getItem("isLoggedIn"))
        window.location.href = '/';
    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_freeproducts'))
        {
          window.location.href = '/unauthorized';
        }
    function cancelbtn(e){
        e.preventDefault();
        window.location.href='/freeproducts';
        return false;
    }

    useEffect(()=>{
        setCheckedState(
            new Array(products.length).fill(false)
          );
          setInputlimitField('');
          setLoading(true);
          setCheckedval([]);
    },[products])

    useEffect(()=>{
        let arry =[];
            {
            arry= new Array(freeproducts.length).fill(false)
            }
          setCheckedfreeState(arry);
          setInputfreelimitField('');
          setfreeLoading(true);
          setCheckedfreeval([]);
    },[freeproducts])

    useEffect(()=>{
        setCheckedState(
            new Array(products.length).fill(false)
          );
          setCheckedval([]);
          
    },[inputlimitField])

    useEffect(()=>{

        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }

        var priceCode = "PL1";
        let URL =  `${BaseUrl}admin/productprice/${priceCode}`;
        http.get(URL).then((response)=>{
  
            let responseData = response.data.data;
            setProducts(responseData);
            setfreeProducts(responseData);
        }).catch((err) => {
            console.log(err);
            setLoading(true);
            //this.setState({ loading: false })
        })

    },[])

  // Map of category names to category IDs
  const categoryMap = {
    pizza: ['1'],
    pasta: ['10', '13'],
    burrito: ['11', '12'],
  };

  const handleOnChange = (position,e) =>{
   
    const updatedCheckedState = checkedState.map((item, index) =>{     
        return index === position ? !item : item;
    }
  );
    
    var updatedList = [...checkedval];
   
        if (e.target.checked) {
            updatedList = [...checkedval, e.target.value];
          } else {
            updatedList.splice(checkedval.indexOf(e.target.value), 1);
          }
          setCheckedval(updatedList);

    setCheckedState(updatedCheckedState);
  }

  const handleOnChange1 = (position,e) =>{

    const updatedfreeCheckedState = checkedfreeState.map((item, index) =>{     
        return index === position ? !item : item;
    }
  );
    
    var updatedList = [...checkedfreeval];
   
        if (e.target.checked) {
            updatedList = [...checkedfreeval, e.target.value];
          } else {
            updatedList.splice(checkedfreeval.indexOf(e.target.value), 1);
          }
          setCheckedfreeval(updatedList);

    setCheckedfreeState(updatedfreeCheckedState);
  }

  const submitButton = (e) =>{
    
    e.preventDefault();
    const isValid = formvalidation();

    if(isValid)
    {   
        let payload = {
            offerTitle: inputFieldtitle.trim(),
            categoryId: 1,
            productToBeAdded: checkedval.toString(),
            productQtyToBeAdded: parseInt(inputlimitField),
            freeProductToAdd:checkedfreeval.toString(),
            freeProductQtyToAdd : parseInt(inputlimitField),
            freeCategoryId:1,
            productQtyExtToBeAdded: 5,
            freeExtProductToAdd: 5,
            productExtToBeAdded : checkedfreeval.toString(),
            status: 'A',

           //status:status,
           }
          var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
                loginDetails = JSON.parse(sessionStorage.getItem('authData'));
          }

          let URL = BaseUrl + "admin/offer";
          http.post(URL, payload).then((response) => {
                let responseData = response.data.data;
                
                if (response.data.success === true) {
                    setAlertPopup(true);
                    setAlertMessage('Free products Setup have been added successfully'); 
                  
                  }
           }).catch((err) => {
              console.log(err);
              
           })

    }
    
    }
    const handleclick=()=>{
        setExpanded(!expanded);
        setfreeLoading(false);

    }

    const formvalidation = () => {
        const errmessage = {};
        let isValid = true;
        
        if(inputFieldtitle == '' )
        {
            errmessage.inputfieldtitleerr = "Title is Required";
            isValid = false;
        }
       
        if(inputlimitField == '' )
        {
            errmessage.inputlimitfielderr = "Product Limit is Required";
            isValid = false;
        }

        if(checkedval == '' )
        {
            errmessage.checkedValerr = "Prodcuts should be selected based on the limit";
            isValid = false;
        }

        if(checkedfreeval == '' )
        {
            errmessage.checkedfreeValerr = "Prodcuts should be selected based on the limit";
            isValid = false;
        }
        setErrMessage(errmessage);
        return isValid;
    }
     // Handle category checkbox change
     const handleCategoryChange = (e) => {
        const { name, checked } = e.target;
    
        setSelectedCategories((prevSelectedCategories) => {
          if (checked) {
            // Add the category if it's checked
            return [...prevSelectedCategories, name];
          } else {
            // Remove the category if it's unchecked
            return prevSelectedCategories.filter((cat) => cat !== name);
          }
        });
      };
      const handleFreeCategoryChange = (e) => {
        const { name, checked } = e.target;
    
        setSelectedFreeCategories((prevSelectedFreeCategories) => {
          if (checked) {
            // Add the category if it's checked
            return [...prevSelectedFreeCategories, name];
          } else {
            // Remove the category if it's unchecked
            return prevSelectedFreeCategories.filter((cat) => cat !== name);
          }
        });
      };
      

  const eventhandlechange = (e) =>{
    const re = /^[0-9\b]+$/;
    if(e.target.name === 'inputFieldtitle')
    {
        setInputFieldtitle(e.target.value);
       
    }

    if(e.target.name == 'inputlimitField')
    {
        if(e.target.value === '' || re.test(e.target.value)){
        setInputlimitField(e.target.value);
        setLoading(false);
        }
        
    }

    if(e.target.name === 'inputfreelimitField')
    {
       setInputfreelimitField(e.target.value);
       setfreeLoading(false);
    }
}
    const getFilteredProducts = () => {
        // If no category is selected, show all products
        //if (selectedCategories.length === 0) return products;

        const selectedCategoryIds = selectedCategories
        .map((category) => categoryMap[category])
        .flat(); // Flatten the array to merge all IDs into one array
        // Filter products based on the selected category IDs
      return products.filter((prd) => selectedCategoryIds.includes(prd.categoryId));
    };
    const getFilteredFreeProducts = () => {
       const selectedFreeCategoryIds = selectedFreeCategories
        .map((category) => categoryMap[category])
        .flat(); // Flatten the array to merge all IDs into one array
        // Filter products based on the selected category IDs
      return freeproducts.filter((prd) => selectedFreeCategoryIds.includes(prd.categoryId));
    };



  const filteredProducts = getFilteredProducts();
  const filteredFreeProducts = getFilteredFreeProducts();
  
    return (<section className="dashboard-block">
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
    <Sidebar />
    
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
        <div className="container">
                     <h1 className="checkout-title">Add Free Products Setup</h1>

                <div className="right-table-outer-wrap">
               <form method='post' className="addform editform freeprod-form " onSubmit={submitButton}>
                     <div className="freeproduct-fieldset">
                        <div className="input-field">
                        <label>Title</label>
                        <input type="text" value={inputFieldtitle} name="inputFieldtitle" onChange={eventhandlechange} className="form-control" placeholder="Title" /> 
                        <div style={{color:"red"}}>{errmessage.inputfieldtitleerr}</div>
                        </div>

                        {/* <div className="input-field">
                        <label>Select Product Category</label>
                            <select onChange={e => handleChange(e.target.value)}>
                                <option value="">Select Category</option>
                                {categories.map((cate,index) =>
                                <option value={cate.categoryName}>{cate.categoryName}</option>
                                )}                            
                              
                            </select>
                            <div style={{color:"red"}}>{errmessage.categoryValerr}</div>
                        </div> */}
                        <div className="input-field">
                        <label>Product Limit to be Added </label>
                            <input type="text" value={inputlimitField} name="inputlimitField" onChange={eventhandlechange} className="form-control" placeholder="Products Limit" />
                            <div style={{color:"red"}}>{errmessage.inputlimitfielderr}</div>
                        </div>

                    
                    </div>

                
                    {!loading && 
                    
                    
                    <div className='custom_check-box'>

                        <div className='edit-free-cat-disp'>
                            <h4>Please Select the Categories to add</h4>
                            <div className='edit-free-cat-chks'>
                                <div class="check-box">
                                    <input type="checkbox" id="cat01" name="pizza" onChange={handleCategoryChange}/>
                                    <label for="cat01">Pizza</label>
                                </div>
                                <div class="check-box">
                                    <input type="checkbox" id="cat02" name="pasta" onChange={handleCategoryChange} />
                                    <label for="cat02">Pasta, Rice & Noodle Sauces</label>
                                </div>
                                <div class="check-box">
                                    <input type="checkbox" id="cat03" name="burrito" onChange={handleCategoryChange} />
                                    <label for="cat03">Burrito</label>
                                </div>
                            </div>
                            
                           {filteredProducts.length>0 &&  <h5>Products to be Added </h5>}
                        {filteredProducts.map((prd,index) => 
                        { const isDisabled = prd.PDP_Status === 'deactivate';
                            
                            return (
                                
                        <div className='check-box'>
                            <input type='checkbox' id={`chkbox${index}`} 
                            name='prd_chkbox' 
                            value={prd.productNumber} 
                            checked={checkedState[index]}
                            onChange={(e) => handleOnChange(index,e)}
                            disabled={isDisabled} // Use disabled for inactive products
                            />
                            <label htmlFor={`chkbox${index}`}>{prd.productDescription}{'('+ prd.productNumber +')'} {isDisabled ? '(Inactive)' : ''}</label>
                            
                            </div>)}
                            )}
                      
                       <div style={{color:"red"}}>{errmessage.checkedValerr}</div> 
                        </div>
                        
                        
                    </div>
                    }
                    
                      {!loading && 
                     <div className='accordion-box'>
                        <div onClick={handleclick}  className={(expanded === true)? 'accordion-header show':'accordion-header'} >
                            <h2>Free Products</h2>
                            <span className='show-accordion'></span>
                        </div>
                        <div className='accordion-body' style={{display:(expanded === true)?'block':'none'}}>
                        
                          
                            {!freeloading && 
                    
                    <div className='custom_check-box'>
                       <div className='edit-free-cat-disp'>
                            <h4>Please Select the Categories to display Free products</h4>
                            <div className='edit-free-cat-chks'>
                                <div class="check-box">
                                    <input type="checkbox" id="fcat01" name="pizza" onChange={handleFreeCategoryChange}/>
                                    <label for="fcat01">Pizza</label>
                                </div>
                                <div class="check-box">
                                    <input type="checkbox" id="fcat02" name="pasta" onChange={handleFreeCategoryChange} />
                                    <label for="fcat02">Pasta, Rice & Noodle Sauses</label>
                                </div>
                                <div class="check-box">
                                    <input type="checkbox" id="fcat03" name="burrito" onChange={handleFreeCategoryChange} />
                                    <label for="fcat03">Burrito</label>
                                </div>
                            </div>
                            {filteredFreeProducts.map((prd,index) => 
                         (!checkedval.includes(prd.productNumber) ) &&
                        <div className='check-box'>
                            <input type='checkbox' id={`freechkbox${index}`} 
                            name='freeprd_chkbox' 
                            value={prd.productNumber} 
                            checked={checkedfreeState[index]}
                            onChange={(e) => handleOnChange1(index,e)}
                            disabled = {(checkedval.includes(prd.productNumber) )}  
                            />
                            <label for={`freechkbox${index}`}>{prd.productDescription} {'('+ prd.productNumber +')'}</label>
                            
                            </div>
                            )}
                       
                       <div style={{color:"red"}}>{errmessage.checkedfreeValerr}</div> 
                        </div> 
                        
                    </div>
                    }
                        </div>
                    </div>
}  
                   
                    
                   <button type='submit'>Submit</button> &nbsp;&nbsp;
                   <button onClick={(e) =>cancelbtn(e)}>Cancel</button>
                   </form>
                </div>
            </div>
        </section>
        <Footer/>
            </div>
           
        </section>);

}
export default AddFreeproducts;
import React, { Component } from 'react';


class Commonalert extends Component {

    constructor(props){
        super(props)
        this.state = {
          
        }
        
    } 
    

	popupClose(){

	   var elems = document.getElementsByClassName('common_alert_popup');
        for (var i=0;i<elems.length;i+=1){
          elems[i].style.display = 'none';
        }
       var loc =  window.location.pathname;
        if(window.location.pathname === '/social-links')
        {
            window.location.reload();
        }
        if(window.location.pathname === '/changepassword')
        {
            window.location.reload();
        }
        if(window.location.pathname === '/holiday/add/')
        {
            window.location.href='/holiday';
        }
        if(window.location.pathname === '/freeproducts/addfreeproducts')
        {
            window.location.href='/freeproducts';
        }
        if(window.location.pathname === `/freeproducts/editfreeproducts/${loc.split('/').at(-1)}`)
        {
            window.location.href='/freeproducts';
        }
        if(window.location.pathname === '/ingredients/add/' || window.location.pathname === `/ingredients/edit/${loc.split('/').at(-1)}`)
        {
            window.location.href='/ingredients';
        }
        if(window.location.pathname === '/allergens/add/' || window.location.pathname === `/allergens/edit/${loc.split('/').at(-1)}`)
        {
            window.location.href='/allergens';
        }
        if(window.location.pathname === `/master-entry/${loc.split('/').at(-1)}` || window.location.pathname === `/master-entry/add/${loc.split('/').at(-1)}`)
        {
          
            window.location.href=`/master-entry/${loc.split('/').at(-1)}`;
        }
        if(window.location.pathname === `/master-entry/edit/${loc.split('/').at(-2)}/${loc.split('/').at(-1)}`)
        {
            window.location.href=`/master-entry/${loc.split('/').at(-2)}`;
        }
        
        if(window.location.pathname === `/role/addRoles/` || window.location.pathname === `/role/editRoles/${loc.split('/').at(-1)}`)
        {
           
            window.location.href=`/role`;
        }
        if(window.location.pathname === '/products')
        {
            window.location.reload();
        }
        if(window.location.pathname === '/account/adduser')
        {
            window.location.href=`/account`;
        }
        if(window.location.pathname === '/account')
        {
            window.location.reload();
        }
        if(window.location.pathname === `/resetpassword/${loc.split('/').at(-2)}/${loc.split('/').at(-1)}`)
        {
            window.location.href='/';
        }
        if(window.location.pathname === '/freecode/add/' || window.location.pathname === `/freecode/edit/${loc.split('/').at(-1)}`)
            {
                window.location.href='/freecode';
            }
	}

    render() {
         var elems = document.getElementsByClassName('common_alert_popup');
            for (var i=0;i<elems.length;i+=1){
              elems[i].style.display = 'block';
            }
        return( <> {<div className='custom-alert-pop common_alert_popup' style={{display: "block"}}>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>{this.props.title}</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>{this.props.message}</p>

                                <div className='alert-buttons'>
                                    <a onClick={this.popupClose.bind(this)} className="popup-alert-btn button">OK</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> }

       </> );

    }

}

export default Commonalert;
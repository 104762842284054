import { useEffect, useState, memo } from "react";
import { Bar } from "react-chartjs-2";
import { http } from "../helper";
import { BaseUrl } from "../common/config";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

Chart.register(CategoryScale);

export const Daywisereport = memo(({ }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState(0);
    const [filtertext, setFilterText] = useState('');
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    // Custom plugin to display "No data found"
    const noDataPlugin = {
        id: "noDataPlugin",
        beforeDraw: (chart) => {
            const { datasets } = chart.data;
            const hasData = datasets.some((dataset) => dataset.data.length > 0);

            if (!hasData) {
                const ctx = chart.ctx;
                const { width, height } = chart;

                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "16px Arial";
                ctx.fillStyle = "#eb9f9d";
                ctx.fillText("Sorry, no data found for your filtered search.", width / 2, height / 2);
                ctx.restore();
            }
        },
    };

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const [revenueChartData, setRevenueChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Day wise Report",
                data: [],
                backgroundColor: ["rgba(252,194,84,1)", "#f4ecec"],
                borderColor: "#e6e6e6",
                borderWidth: 2,
            },
        ],
    });



    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    useEffect(() => {
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let payload = {};
        if (filter === 'custom') {
            payload = {
                filter: filter, datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                dateto: format(new Date(toDate), "yyyy-MM-dd")
            }
            setShowDatePicker(false);
        }
        else {
            payload = {
                filter: parseInt(filter), datefrom: '',
                dateto: ''
            }
        }

        let URL3 = BaseUrl + "admin/daywisereport";
        http
            .post(URL3, payload)
            .then((response) => {
                setIsLoading(false);
                const chartData = response.data.data.filter((item) => item.sum > 0)
                const total = chartData.reduce((total, item) => {
                    return total + item.sum;
                }, 0);
                setTotalRevenue(total);
                setRevenueChartData({
                    labels: chartData.map((item) => dayNames[item._id - 1]),
                    datasets: [
                        {
                            label: "Total Revenue (£)",
                            data: chartData.map((item) => item.sum.toFixed(2)),
                            backgroundColor: ["#cccccc", "#70dbd8"],
                            borderColor: "#E5E5E5",
                            borderWidth: 0,
                            barThickness: 40,
                            maxBarThickness: 60,
                            borderRadius: 4,

                        },
                    ],
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [filter, fromDate, toDate]);

    const changeFilterHandler = (e) => {

        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === 'custom') {
            setShowDatePicker(true);

        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);

        }
    };

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    }

    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    let loadingbuffer = isLoading ? 'loading-buffer' : '';
    return (

        <div className={`chart-container chart-week-revenue ${loadingbuffer}`}>
            <div className="chart-Hdr">

                <h2 style={{ textAlign: "left" }}>
                    Day wise Revenue Analysis{"  "}
                </h2>
                <div className="chart-fltr" onMouseEnter={() => {
                    if (filter === "custom" && !showDatePicker) {
                        setShowDatePicker(true);
                    }
                }}
                    onMouseLeave={() => {
                        if (filter === "custom" && showDatePicker) {
                            setShowDatePicker(false);

                        }
                    }}>
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}
                    ><option value={0}>All</option>
                        <option value={30}>Past 30 Days</option>
                        <option value={90}>Past 90 Days</option>
                        <option value={180}>Past 180 Days</option>
                        <option value={365}>Past 1 Year</option>
                        <option value={'custom'}>Custom Date</option>
                    </select>}
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => DateChange(date, "from")}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                            <label>To:</label>
                            <DatePicker
                                selected={toDate}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => DateChange(date, "to")}
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                        </div>
                    )}
                </div>
                <div className="fltr-sub-val">
                    {totalRevenue > 0 && (<span className='fltr-val'>Total Revenue: <strong>{priceFormat(totalRevenue)}</strong> ({(filtertext != '' && filtertext != 'Custom Date') ? filtertext : (filtertext === 'Custom Date' && fromDate && toDate) ? formatDate(fromDate) + ' - ' + formatDate(toDate) : 'All'})</span>)}
                </div>
            </div>
            {revenueChartData && (
                <Bar
                    data={revenueChartData}
                    plugins={[noDataPlugin]}
                    options={{
                        scales: {
                            y: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)',
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                }
                            },
                            x: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)'
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                    callback: function (value, index, ticks) {
                                        const label = this.getLabelForValue(value); // Get the label for the current tick
                                        if (typeof label === 'string' && label.includes(" ")) {
                                            const [date, dayOfWeek] = label.split(" "); // Split by the comma separator
                                            return [dayOfWeek, date];  // Return the label with a newline
                                        }
                                        return label; // Single-line label for other cases
                                    },
                                }
                            }
                        },
                        plugins: {
                            title: {
                                display: false,
                                text: "Revenue Analysis",
                            },
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                titleColor: 'rgba(220, 222, 226, 1)',
                                bodyColor: 'rgba(220, 222, 226, 1)'
                            },
                            datalabels: {
                                display: false,
                                font: {
                                    weight: 'bold'
                                },
                                anchor: 'end',
                                align: "top",
                                formatter: function (value) {
                                    return "£" + value;
                                }
                            }
                        },
                    }}
                />
            )}
        </div>
    );
});
